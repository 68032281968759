import React from 'react';
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './Header.css';
const Header3 = () => {
  return (
    <div>
      <h1 id="hr1" style={{padding:0,margin:0}}></h1>
      <div style={{margin:'3%',padding:'0',marginTop:'0px'}}>
      <nav className="navbar navbar-light bg-light"  style={{padding:'0px'}}>
        <a className="navbar-brand" href="/createproject">
          <img
            src="./images/Logo.png"
            width="120"
            height="100"
            className="d-inline-block align-top"
            alt=""
          />
        </a>
        
{/*         
         <Link to='/createproject'><button
            className="btn btn-warning"
            style={{fontSize:'20px',width:'157px',backgroundColor:'#F27A18',color:'white',fontFamily:'poppins',marginLeft: '-25%'}}
          >
                BACK
          </button></Link> */}
          
        
      </nav>
      {/* <hr style={{color: '#ccc'}} /> */}
    </div>
    </div>
  );
};
export default Header3;
