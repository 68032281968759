import React from 'react';
import { Link } from "react-router-dom";
import { BasicAuth, configData } from '../BusinessLogic/JavaApis/JavaApi';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './Header.css';
const Header = () => {
  const [username, setUserName] = React.useState('');
  const [notificationUrl, setNotificationUrl] = React.useState('');
  React.useEffect(() => {
    const textFromStorage = localStorage.getItem('my-key');
    setUserName(textFromStorage);
    var credentials = window.btoa(process.env.REACT_APP_AuthenticationName + ":" + process.env.REACT_APP_AuthenticationPassword);
    var auth = { "Authorization": `Basic ${credentials}` };
    fetch(configData, {
      headers:BasicAuth,
    })
      .then(response => response.json())
      .then(response => {
        setNotificationUrl(response[0].notificationpath);
      });
  }, []);
  const clearStorage = () => {
    localStorage.removeItem('my-key');
    localStorage.removeItem('user-id');
    localStorage.removeItem('temp_userName');
    localStorage.removeItem('userCred');
    localStorage.removeItem('project-id-is');
    localStorage.removeItem('project-name-is');
    localStorage.removeItem('Nambri_user_name_is');
  };
  return (
    <div style={{ margin: '0', padding: '0' }}>
      <nav className="navbar navbar-light bg-light" style={{ padding: '0px' }}>
        <a className="navbar-brand" href="/createproject">
          <img
            src="./images/Logo.png"
            width="120"
            height="100"
            className="d-inline-block align-top"
            alt=""
          />
        </a>

        <div className="dropdown" style={{ outline: 'none' }} id="dropid">
          <a id="icon">
            <i onClick={() => { window.open(process.env.REACT_APP_HOST_UI+'/NambriHelpContent'); }} className="fa fa-question-circle" style={{ fontSize: '110%', marginRight: '15px' }} />  {' '}
            <span id='v1'></span>
            <i className="fa fa-user" aria-hidden="true" style={{ color: '#F68324', fontSize: '110%' }}></i>
          </a>
          <button
            type="button"
            className="btn btn-lignt dropdown-toggle"
            data-bs-toggle="dropdown"
            style={{ fontSize: '17px', fontFamily: 'poppins' }}
          >

            {username}
          </button>
          <ul className="dropdown-menu" style={{ border: 'none' }} id="dropmenuscss">
            {/* <li>
              <button className="dropdown-item" type="button">
                Account Profile
              </button>
            </li> */}
            <li>
              <Link to='/licenseterms' className="linkHeader">
                <button className="dropdown-item " type="button">
                  License Terms
                </button></Link>
            </li>
            {/* <li>
              <button className="dropdown-item" type="button">
                Payment Profile
              </button>
            </li> */}
            <li>
              <Link to="/updateprofile" className="linkHeader"><button className="dropdown-item" type="button">
                User Profile
              </button></Link>
            </li>
            {/* <hr /> */}
            <li>
              {/* <button className="dropdown-item" type="button" onClick={() => clearStorage()}>Sign Out</button> */}
              <Link to="/" className="linkHeader"><button className="dropdown-item" type="button" onClick={() => clearStorage()}>Sign Out</button></Link>
            </li>
          </ul>
        </div>
      </nav>
      {/* <hr style={{color: '#ccc'}} /> */}
    </div>
  );
};
export default Header;
