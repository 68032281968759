import React from 'react';
import Header3 from '../Header3.js';
import { Link } from "react-router-dom";
import './Licenseterms.css'

const LicenseTerms = () => {
    return(
        <div>
            <Header3/>
            <div  style={{marginLeft:'3%',marginRight:'3%',marginTop:'-70px'}}>
                <br/>
                <div className='square border border-3' style={{border:'2px ridge black',padding:'25px' , height:'75vh',overflow:'auto',marginBottom:'10px'}}>
                <h4>1. DEFINITIONS</h4>
                <p>As used in this Agreement, the terms with initial letters capitalized, whether used in the singular or plural form, shall have the meanings set forth in this Article 1 or, if not listed below, the meaning designated in places throughout this Agreement.</p>
                <ol type='a'>
                    <li><b>“<u>Authorized User</u>”</b> shall include employees of Customer, its agents, or contractors.</li>
                    <li><b>“<u>Customer</u>”</b> ,“Customer Entity” or “Customer Entities” shall mean, individually and collectively, Customer and the Customer Affiliates.</li>
                    <li><b>“<u>Customer Affiliate</u>”</b> or “Customer Affiliates” means, individually and collectively the legal entities listed by the Customer Entity.</li>
                    <li><b>“<u>Documentation</u>”</b> shall mean user guides and manuals for installation and Use of the Licensed Programs software.</li>
                    <li><b>“<u>Licensor Intellectual Property</u>”</b> means the know-how, research, and other intellectual property rights and tangible embodiments thereof (in whatever form or medium) which relate to Licensed Programs.</li>
                    <li><b>“<u>Licensor</u>”</b> engages in the research and development of proprietary Licensed Programs. The Licensor is the owner of the trademark, technology and Licensed Programs, based on the terms and conditions of the license agreement (“Agreement”). </li>
                    <li><b>“<u>Licensed Programs</u>”</b> shall mean any Licensor software products which Customer has ordered under this Agreement as well as Documentation and any Updates.</li>
                    <li><b>“<u>Term</u>”</b> means the term of this Agreement as set forth in Section 13(a).</li>
                    <li><b>“<u>Third Party</u>”</b> means any entity or person other than Licensee or its Customer Affiliates</li>
                    <li><b>“<u>Update</u>"</b> shall mean a release, patch or version of the Program which Licensor generally makes available to its other Program licensees under technical support for no additional license fee. Update shall not include any release, option or future product which Licensor requires its customers generally to license separately.</li>
                    <li><b>"<u>Use</u>"</b> shall mean: to process and execute instructions, statements and data included in, or input to, the Licensed Programs.</li>
                    <li><b>“<u>Work Product</u>”</b> defined and as set forth in Section 5. </li>
                </ol>
                <h4>2. Purpose of This Agreement</h4>
                <ol type='a'>
                    <li><u>Purpose.</u> This Agreement governs terms and conditions of License Programs from Licensor.</li>
                    <li><u>Nature.</u> This Agreement is non-exclusive and shall not be construed to require Customer to purchase any specific number of License Programs or services from Licensor. </li>
                </ol>
                <h4>3. LICENSE GRANT</h4>
                <ol type='a'>
                    <li><u>Grant.</u> Licensor hereby grants to Customer with regard to the Licensed Programs and quantity the number of licenses referenced, a non-exclusive, non-transferable, worldwide, fully paid, limited license (without the right to sublicense, rent/lease, sell, grant a security interest in, or otherwise transfer any rights in any of the Licensed Programs) for the license term for each such Licensed Program to:</li>
                    <ol type='i'>
                        <li><u>Internal Use:</u> use the Licensed Programs solely for a Customer Entity’s operations, consistent with the Use limitations specified or referenced in this Agreement.</li>
                        <li><u>Scope of License:</u> The limited license granted to Customer by this Agreement permits Customer to access and use the Licensed Programs that Customer subscribes to or purchase as set forth herein and for no other purpose. Customer may use such Licensed Programs in conjunction with preparing any work product deliverable for a client, whether written or oral, prepared by Customer for themselves or Customer’s direct client. If Customer intends to share such Licensed Programs or allow others in their company or firm to access or use such Licensed Programs, Customer must purchase a multi-seat license as the limited license granted by this Agreement does not permit such use.</li>
                        <li><u>Documentation Use:</u> use the Documentation provided with the Licensed Programs in support of the licensed Use of the Licensed Programs; and</li>
                        <li><u>Use by Affiliates:</u> extend any or all of the above license rights to any Customer Affiliate, at no additional cost or expense, provided such license rights are subject to and in accordance with the terms of this Agreement and either Customer assumes all liability for breach of this Agreement by such other Customer Entities or Customer procures, in advance of the applicable Customer Entity’s Use, an agreement of the Customer Entity, enforceable by Licensor, to abide by the terms of this Agreement.</li>
                    </ol>
                    <li><u>No Grant.</u> No license of any kind is granted hereunder for any other purpose including, for example, creating and distributing implementations of any of the Licensed Programs or sharing access to any of the Licensed Programs to third parties (including, without limitation, providing access to or demonstrating any of the Licensed Programs to any party that may reasonably be deemed a competitor of Licensor without the prior written consent of Licensor, which consent may be withheld, delayed, or conditioned within the sole discretion of Licensor).<br/>
                    As the licensee, Customer may not distribute, modify, transmit, reuse, repost, resell, or share any of the Licensed Programs, whether internally within Customer firm/company or externally outside of Customer firm/company for any purpose, without Nambri’s written permission, which permission may be withheld in its sole discretion. Nor may Customer use Nambri’s Licensed Programs as the foundation for, or in conjunction with the development of, a tool, calculator, model, or any other product that is developed for use or purchase by others within Customer firm/company or outside of Customer firm/company, regardless of the purpose.
                    </li>
                    <li><u>Marks.</u> No right, title, or interest in or to any trademarks, service marks, or trade names of Licensor or Licensor's licensors, Licensor or the Licensor's licensors is granted hereunder. Nambri and Nambri-related logos, marks and names are trademarks of Licensor.</li>
                    <li><u>Title.</u> As between Customer and Licensor, Licensor shall retain all title, copyright and other proprietary rights to the Licensed Programs and any results of the services.</li>
                    <li><u>Prohibitions.</u> Customer will not, and will not permit any Customer Affiliate, employee or other third party obtaining use of the Software through Customer, to</li>
                    <ol type='i'>
                        <li>copy or Use the Licensed Programs except as specified in this Agreement or the applicable Ordering Document;</li>
                        <li>reverse engineer, decompile or disassemble, or translate the Licensed Programs or otherwise attempt to derive source code from any portion of a Licensed Program; </li>
                        <li>unlock or bypass any initialization system or encryption capability of a Licensed Program;</li>
                        <li>modify any Licensed Program or combine any Licensed Program with any other software; or </li>
                        <li>copy, translate, reproduce, prepare derivative works from (including translations, adaptations, arrangements).</li>
                        {/* <br/> */}
                        Customer will not, and will not permit any Customer Affiliate, employee or other third party to
                        <ol type='i'>
                            <li>remove any product identification or copyright, proprietary or other notices or </li>
                            <li>permit the public disclosure of the results of any Licensed Program benchmark tests.</li>
                        </ol>
                    </ol>
                    <li><u>Third Party Use;</u> Disclosure. Customer will not, and will not permit any Customer Affiliate, employee or other third party obtaining use of the Licensed Programs through Customer, to </li>
                    <ol type='i'>
                    <li>use the Licensed Programs to support any third party’s business operations,</li>
                    <li>re-license, sublicense, rent or lease the License Programs,</li>
                    <li>deploy or use the Licensed Programs for, commercial time-sharing or service bureau use.</li>
                    </ol>
                    <li><u>Third Party Grants.</u> Customer shall not grant any interest in the Licensed Programs and/or services to another party (if Customer does grant any security interest in the Licensed Programs and/or services, the secured party has no right to use, transfer or assign the Licensed Programs and/or services and any act by any such secured party that places Customer in breach of this Agreement shall give Licensor the right to terminate the license(s) granted for such Licensed Programs).</li>
                </ol>
                <h4>4. AUDIT</h4>
                <p>If Licensor reasonably believes that an audit is necessary, Licensor may audit Use of the Licensed Programs by Customer or by Customer Affiliates which have ordered Licensed Programs under this Agreement or to whom Customer has extended Usage rights hereunder.   If an audit reveals that such Customer Entity(ies) has(have) underpaid fees to Licensor and Customer agrees with the findings of the audit, Customer shall be invoiced for such underpaid fees.</p>
                <h4>5. WORK PRODUCT</h4>
                <p>All code, designs, drawings, reports, technical data, deliverables, methodologies, blueprints, diagrams, charts, functional descriptions, photographs, surveys, or other materials, writings, or works of authorship (and any drafts of the foregoing) made, conceived, or developed pursuant to or in furtherance of technical support by any employee(s) of Licensor (collectively, <b>“<u>Work Product</u>”</b>) shall be the exclusive property of Licensor. In addition, unless expressly stated otherwise, none of the forgoing shall be works made for hire under the copyright laws of the United States.</p>
                <h4>6. REPRESENTATIONS AND WARRANTIES OF LICENSOR</h4>
                <ol type='a'>
                    <li><u>Program Warranties</u></li>
                    <ol type='i'>
                        <li><u>Title.</u> Licensor represents and warrants that it has the authority to grant the rights in this Agreement for the Licensed Programs licensed.</li>
                        <li><u>Conformity.</u> Licensor represents and warrants that each Licensed Program will materially conform to the applicable Documentation, for one year (1) after Licensor delivers the Licensor Program to Customer</li>
                        <li><u>Security Defects.</u> Licensor shall repair or otherwise properly mitigate (via technical support) any security defect in the Licensed Programs.  Further, Licensor represents that it follows industry standards for security in the process of development for the Licensed Programs. Also, Licensor will notify Customer of material security vulnerabilities in accordance with Licensor's standard processes to notify customers of such issues.</li>
                        <li><u>Financial Reports.</u> Although we aim to provide correct information in the financial reports, we cannot guarantee its accuracy. We are not responsible for any errors in information listed in the financial reports. We do not guarantee that the financial reports are correct, reliable, or error-free. We, in our sole discretion, may choose to delay or cancel any requested financial report. For example, this may happen if we suspect the request has been made fraudulently.</li>
                    </ol>
                    <h6>LICENSOR DOES NOT GUARANTEE THAT THE LICENSED PROGRAMS WILL PERFORM ERROR-FREE OR UNINTERRUPTED OR THAT LICENSOR CAN CORRECT EACH AND EVERY ERROR. TO THE EXTENT PERMITTED BY LAW, THE WARRANTIES SET FORTH IN THIS AGREEMENT ARE EXCLUSIVE AND THERE ARE NO OTHER EXPRESS OR IMPLIED WARRANTIES OR CONDITIONS, INCLUDING WARRANTIES OR CONDITIONS OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.</h6>
                    <li><u>Limitations.</u></li>
                    <h6>THE WARRANTIES IN THIS SECTION ARE GIVEN IN LIEU OF ALL OTHER WARRANTIES AND CONDITIONS. THERE ARE NO OTHER EXPRESS OR IMPLIED WARRANTIES OR CONDITIONS, INCLUDING THE IMPLIED WARRANTY OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.</h6>
                </ol>
                <h4>7. TERMS OF PAYMENT; TAXES</h4>
                <p>Customer also agrees to pay any sales, value-added or other similar taxes imposed by applicable law which Licensor must pay based on the Licensed Programs or services Customer ordered.  Such taxes shall be charged at the appropriate rate by Licensor in addition to its stated fees and shall be shown separately on the relevant invoice.</p>
                <p>All payments for subscriptions purchased through Nambri are made through a third-party payment processor. As such, the third-party payment processor’s terms and conditions will apply, and it is Customer responsibility to read any such terms or policies. We will not process Customer purchase until the third-party payment processor notifies us that the full payment has been received. All payment issues with the third-party payment processor, such as but not limited to, denials, or holds on Customer account are Customer sole responsibility. </p>
                <p>Customer subscription will automatically renew and remain in effect until it is cancelled.</p>
                <p>Taxes are based on prevailing rates in your area.</p>
                <h4>CONFIDENTIAL INFORMATION</h4>
                <ol type='a'>
                    <li><u>Confidentiality.</u>  In the course of performing the Agreement, each party may receive from the other, information or data pertaining to technical or business information that the other party desires to protect against further use or disclosure. Unless otherwise expressly agreed to by the parties, <b>"<u>Confidential Information</u>"</b> shall mean: (i) any information or data in written, electronic or other tangible form which is provided by a Customer Entity and labeled as "confidential", "proprietary" or with a legend of similar import; (ii) information obtained from Customer while on or accessing Customer’s premises or facilities, where the context of the disclosure indicates that the information or data is Confidential Information; (iii) the Licensed Programs, whether or not labeled in accordance with this Section 13(a); and (iv) the following information of Licensor whether or not labeled in accordance with this Section 13(a): Documentation, applications’ extension/API source code, data models/process models, data definition language (DDL)/DDL scripts and data schemas, Licensed Program tuning parameters, systems analysis and design specifications, benchmarks of the Licensed Programs provided by Licensor, future Licensed Program offering presentations, business process flows and (v) pricing proposals from Licensor.  Each party shall remain the exclusive owner of its Confidential Information. </li>
                    <li><u>Nondisclosure of Confidential Information.</u> The Confidential Information of the disclosing party may only be disclosed to those employees, contractors or agents of the receiving party that have a need to know in order to perform the obligations under this Agreement and/or use the Licensed Programs and/or services provided under this Agreement.</li>
                    <li><u>Exceptions.</u> The obligations in this Section 13 shall not apply to any portion of any otherwise Confidential Information received from the disclosing party which is: lawfully in the receiving party's possession, with no restriction on use or disclosure, prior to its acquisition from the disclosing party; received in good faith by the receiving party, with no restrictions on use or disclosure, from a third party not subject to a confidential obligation to the disclosing party; now or later becomes publicly known through no breach of confidentiality obligation by the receiving party; or independently developed by or for the receiving party without any reliance on Confidential Information of the disclosing party.</li>
                    <li><u>Disclosure and Notification.</u> If a receiving party receives a request from a government entity to disclose any Confidential Information of the disclosing party the receiving party agrees that, if permitted by law, prior to disclosing any Confidential Information of the disclosing party, it shall promptly notify the disclosing party of the existence and terms of such request in order for the disclosing party to take reasonable action to prevent disclosure.</li>
                    <li><u>Continuing Obligation.</u> The obligation of non-disclosure with respect to Confidential Information of the disclosing party shall survive any termination of this Agreement and shall continue for a period of five years thereafter. Each party agrees to protect Confidential Information from disclosure or distribution by its employees and agents in violation of this Agreement by using such procedures and practices as each party employs to protect its own information of similar type and value with, in any event, at least a reasonable degree of care.</li>
                    <li><u>Consent to Data Usage.</u> By using Licensed Programs, Customer agrees that Customer has read our Privacy Policy. Customer represents that they understand our Privacy Policy and consent to the collection of data and other information under the terms of the Privacy Policy.</li>
                </ol>
                <h4>9. COMPLIANCE WITH LAWS</h4>
                <p>Customer’s use of the Licensed Programs shall comply with applicable local, state, and federal laws. Licensee shall indemnify, defend and hold Licensor harmless for any losses, including without limitation threatened or actual law suits, resulting from Licensee’s breach or alleged breach of the preceding sentence.</p>
                <h4>10. FORCE MAJEURE</h4>
                <p>Neither party shall be responsible for any delay or failure in performing any obligation under this Agreement to the extent that such delay or failure is caused by fire, flood, explosion, war, embargo, government requirement, civil or military authority, acts of God, labor strikes, electrical, internet or telecommunication outage that is not reasonably preventable or caused by the obligated party, any circumstances beyond its control and not involving any fault or negligence of the party affected (<b>“<u>Condition</u>”</b>).  If any such Condition occurs, the party delayed or unable to perform shall promptly give written notice to the other party and if such Condition remains at the end of sixty days from the beginning of the Condition, the affected party may elect (a) to extend the time of performance or (b) to terminate such Statement of Work (“SOW”) or part thereof, or (c) to suspend such SOW for the duration of the Condition. Until notice is given, option (a) shall be deemed selected.</p>
                <h4>11. ASSIGNMENT</h4>
                <p>Neither party may assign this Agreement or any rights or obligations hereunder, in whole or in part, without prior written consent of the other, except as otherwise stated herein. Notwithstanding the foregoing, Licensor may assign this Agreement without having to obtain Customer’s consent for a consolidation, merger or other similar reorganization of Licensor that does not materially affect Customer’s rights under this Agreement. If a Customer Entity grants c in the License Programs and/or any services, the secured party has no right to Use or transfer the Licensed Programs and/or any services. Any assignment or assumption, in whole or in part, of Licensee’s rights or obligations hereunder without the prior written consent of Licensor shall be void and result in the immediate termination of the affected license(s).</p>
                <h4>12. INDEMNIFICATION; INFRINGEMENT</h4>
                <ol type='a'>
                    <li><u>Infringement Indemnity for Programs.</u>  Licensor shall defend and indemnify and hold Customer Entities harmless against a claim that the Licensed Programs infringe a copyright or patent or other intellectual property right, including payment of all damages and costs awarded or paid in settlement, provided that:</li>
                    <ol type='i'>
                        <li>Customer notifies Licensor in writing within thirty days after notice of the claim;</li>
                        <li>Licensor has sole control of the defense and all related settlement negotiations, but with Customer granted the right to participate in such defense or negotiations using counsel of its own choosing and at its expense; and </li>
                        <li> Customer provides Licensor with the reasonable assistance, information and authority necessary to perform Licensor's obligations under this Section 17(b). Notwithstanding the foregoing, Licensor shall not settle or compromise any claims, or consent to the entry of any judgment thereon if such settlement or compromise includes conditions or terms affecting Customer in a manner inconsistent with this Agreement. Licensor shall have no liability for any claim of infringement based on use of a superseded or altered release of Licensed Programs if the infringement would have been avoided by the use of a current unaltered release of the Licensed Programs which Licensor provides to Customer without charge. </li>
                    </ol>
                    <li><u>Self-Discovery of Infringement.</u> If the Licensed Programs are held or are believed by Licensor to infringe, Licensor shall, at its expense, either </li>
                    <ol type='i'>
                        <li>modify the Licensed Program(s) to be non-infringing; </li>
                        <li>obtain for Customer a license to continue using the Program(s);</li>
                        <li>replace the Licensed Program(s) with equivalent, non-infringing Licensed Programs. If it is not commercially reasonable to perform any of the above options, then Licensor may terminate the license for the infringing Licensed Program(s), provided it is similarly terminating licenses for all other licensees of the Licensed Program(s), and refund the license fees paid that are attributable to the Licensed Program(s). This Section 17(c) states Licensor’s entire liability and Customer's exclusive remedy for infringement for Licensed Programs. </li>
                    </ol>
                </ol>
                <h4>13. TERM AND TERMINATION</h4>
                <ol type='a'>
                    <li><u>Term.</u> This Agreement shall be effective on the Effective Date specified below and shall continue for a period of one year (1) (the “Term”), unless earlier terminated in accordance with the provisions of this Agreement or extended by mutual written agreement of the parties hereto</li>
                    <li><u>Termination by Licensor.</u> Licensor may terminate this Agreement upon written notice if a Customer Entity materially breaches this Agreement and such material breach is not cured within thirty days (30) following written notice to the Customer Entity specifying the breach.  </li>
                    <li><u>Effect of Termination.</u> Termination of this Agreement shall not affect any License rights of Customer Entities, and shall not limit either party from pursuing other remedies available to it, including injunctive relief, nor shall such termination relieve any of the Customer Entities of their obligation to pay all fees owed (whether or not yet billed) by any Customer Entity.</li>
                    <li><u>Termination of License.</u> In the event that a court of competent jurisdiction rules that Customer has materially violated the terms of a license (including payment therefore) and Customer fails to cure such violation within thirty days (30) after delivery of such ruling, Licensor may terminate the applicable license. The foregoing shall not be deemed to preclude Licensor from seeking injunctive relief to restrain actual or threatened violation of license terms. </li>
                </ol>
                <h4>14. LIMITATION OF LIABILITY</h4>
                <p>IN NO EVENT SHALL EITHER PARTY BE LIABLE TO THE OTHER FOR ANY INDIRECT, INCIDENTAL, EXEMPLARY, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND INCLUDING LOST REVENUE, LOST PROFITS, LOST DATA AND THE LIKE ARISING OUT OF OR IN CONNECTION WITH THIS AGREEMENT EVEN IF THE PARTY HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.  EACH PARTY’S MAXIMUM LIABILITY TO THE OTHER FOR ANY DAMAGES UNDER THIS AGREEMENT, WHETHER IN CONTRACT OR TORT, SHALL BE LIMITED TO ALL AMOUNTS PAID FOR THE LICENSED PROGRAMS AND SERVICES UNDER THIS AGREEMENT.</p>
                <h4>15. DISPUTE RESOLUTION</h4>
                <p>In the event of any dispute or disagreement between the parties, whether with respect to the interpretation of any provision of this Agreement, or with respect to the performance of either party hereto, each of the parties will appoint its representative to meet for the purpose of endeavoring to resolve such dispute or negotiate for an adjustment to such provision. No formal proceedings for the judicial resolution of such dispute, except for the seeking of equitable relief, may begin until the representatives in good faith conclude, after a good faith attempt to resolve the dispute, that amicable resolution through continued negotiation of the matter at issue does not appear likely</p>
                <h4>16. GENERAL</h4>
                <ol type='a'>
                    <li><u>Relationship of Parties.</u> Persons furnished by the respective parties shall not be considered employees of the other party for any purpose. Nothing contained in this Agreement is intended to give rise to a partnership or joint venture between the parties.</li>
                    <li><u>Non-solicitation.</u> During the Term and for a period of one year (1) following the expiration or termination of this Agreement, neither party shall directly or indirectly solicit or participate in any attempt to solicit any person who was an employee of the other party.</li>
                    <li><u>Non-waiver.</u> Either party's delay or failure to enforce any of the provisions of this Agreement, or to exercise any option, shall not be construed as a waiver of such provisions, rights, or options, or affect the validity of this Agreement.</li>
                    <li><u>Severability.</u> If any of the provisions of this Agreement is held to be invalid or unenforceable, then such provision(s) shall be considered of no further force and effect and the Agreement shall be construed as if not containing the particular invalid or unenforceable provision(s), and the rights and obligations of Licensor and Customer shall be construed and enforced accordingly.</li>
                    <li><u>Section Headings.</u> The headings of the sections are inserted for convenience only and are not intended to affect the meaning or interpretation of this Agreement.</li>
                    <li><u>Survival of Obligations.</u> The respective obligations of the parties under this Agreement that by their nature would continue beyond the termination, cancellation or expiration of this Agreement shall survive such termination, cancellation or expiration, including, but not limited to, obligations to indemnify and maintain confidentiality.</li>
                    <li><u>Choice of Law.</u>  The construction, interpretation and performance of this Agreement shall be governed by and construed in accordance with the laws of the State of Florida without regard to any conflicts of law principles that would require the application of the laws of any other jurisdiction. The application of the U.N. Convention on Contracts for the International Sale of Goods is specifically excluded from this Agreement. The Uniform Computer Information Transactions Act shall not apply to this Agreement.</li>
                    <li><u>Statute of Limitations.</u> Except for actions for nonpayment or breach of Licensor’s proprietary rights in the Licensed Programs, no action, regardless of form, arising out of or relating to this Agreement may be brought by either party more than two years (2) after the cause of action has accrued.</li>
                    <li><u>Entire Agreement.</u> This Agreement constitutes the entire agreement between the parties and supersedes all contemporaneous or prior agreements, whether written or oral, with respect to the subject matter of this Agreement, for Licenses purchased under and services provided under this Agreement. With respect to the Prior Agreements, the parties agree that the Prior Agreements shall stay in effect with respect to the licenses for Programs acquired by Customer under those agreements, except as otherwise provided in this Agreement. No amendment or modification may be made to this Agreement unless in writing and signed by appropriate representatives of the parties. </li>
                </ol>
                </div>
            <div style={{float:'right',marginTop:'30px'}}>
          <Link to='/createproject'><button
            className="btn btn-dark fw-bold " id="backbtn"

            // style={{fontSize:'20px',float:"left",width:'257px',color:'white',fontFamily:'poppins'}}
          >
                Back
          </button></Link>
          </div></div>
        </div>
    )
}

export default LicenseTerms;