import React, { useState } from 'react'
import Header3 from '../Header3.js';
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import pdf from './NAMBRI Help Content v2.pdf'

function App() {

  return (
    <div>
      <Header3 />
      <div style={{ marginLeft: '3%', marginRight: '3%', marginTop: '-70px' }}>
        <br />
        <div className='square border border-3' style={{ border: '2px ridge black', padding: '0px', height: '80vh', overflow: 'auto',margin:0 }}>
            <div>
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js">
                  <Viewer
                    fileUrl={pdf} />
              </Worker>
            </div>
        </div>
      </div>
    </div>
  );
}

export default App;
