import React, { useEffect, useState, useRef } from "react";
import './RequestAccess.css';
import ReactPlayer from 'react-player';
import { ReadyAPIBody, BasicAuth, specialCharsError, specialCharsNoError, AlphaSpecialCharsError, NumbersError, createUser, configData, sendEmail } from '../../BusinessLogic/JavaApis/JavaApi';
import { useNavigate } from 'react-router';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { Link } from 'react-router-dom';
import { ThreeDots } from 'react-loader-spinner'

import CryptoJS from "crypto-js";
import moment from 'moment';

/* eslint-disable jsx-a11y/iframe-has-title */
const RequestAccessScreen = () => {
    let navigate = useNavigate();
    const form = useRef();
    const initialValues = { First_Name: '', Last_Name: '', Company_Name: '', Role: '', Phone_Number: '', Email_Address: '' }
    const [formValues, setFormValues] = React.useState(initialValues)
    const [formErrors, setFormErrors] = React.useState({})
    const [isSubmit, setIsSubmit] = React.useState(false)
    const [alertMsg, setAlertMsg] = React.useState('')
    const [showAlert, setShowAlert] = React.useState(false)
    const [oneTimeExe, setOneTimeExe] = React.useState(false)
    const [oneTimeExeForVideo, setOneTimeExeForVideo] = React.useState(true)
    const [AdminEmail_Address, setAdminEmaailAddress] = React.useState('')
    const [alertWrongMsg, setAlertWrongMsg] = React.useState('')
    const [showWrongAlert, setShowWrongAlert] = React.useState(false)
    const [isClickedOnSubmit, setisClickedOnSubmit] = React.useState(false)
    const [videoUrl, setVideoUrl] = useState('')

    const submitSuccess = (event) => {
        window.scrollTo(0, 0)
        event.preventDefault();
        setFormErrors(validate(formValues))
        setIsSubmit(true)
        setOneTimeExe(true) 
    }

    const validate = (values) => {
        const errors = {}
        const regex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
        const specialChars = /[*=;]/;
        values.First_Name = values.First_Name.trim();
        if (!values.First_Name) {
            errors.First_Name = 'First Name is required!'
        } else if (!values.First_Name.match(/^[a-zA-Z`!@#$%^&()_+\-[\]{}':"\\|,.<>/?~\s]*$/)) {
            errors.First_Name = specialCharsNoError;
        }
        values.Last_Name = values.Last_Name.trim();
        if (!values.Last_Name) {
            errors.Last_Name = 'Last Name is required!'
        } else if (!values.Last_Name.match(/^[a-zA-Z`!@#$%^&()_+\-[\]{}':"\\|,.<>/?~\s]*$/)) {
            errors.Last_Name = specialCharsNoError;
        }
        values.Company_Name = values.Company_Name.trim();
        if (!values.Company_Name) {
            errors.Company_Name = 'Company Name is required!'
        } else if (specialChars.test(values.Company_Name)) {
            errors.Company_Name = specialCharsError
        }
        values.Role = values.Role.trim();
        if (!values.Role) {
            errors.Role = 'Role is required!'
        } else if (specialChars.test(values.Role)) {
            errors.Role = specialCharsError
        }
        values.Phone_Number = values.Phone_Number.trim();
        if (!values.Phone_Number.match(/^[ 0-9`!@#$%^&()_+\-[\]{}':"\\|,.<>/?~]*$/)) {
            errors.Phone_Number = AlphaSpecialCharsError
        }
        else if (values.Phone_Number.length > 25) {
            errors.Phone_Number = 'Phone Number length must be less than 25 characters'
        }
        values.Email_Address = values.Email_Address.trim();
        if (!values.Email_Address) {
            errors.Email_Address = 'Email address is required!'
        } else if (!regex.test(values.Email_Address)) {
            errors.Email_Address = 'Invalid email address!'
        }
        return errors
    }


    const setInputValues = (event) => {
        const { name, value } = event.target
        setFormValues({ ...formValues, [name]: value })
        setShowWrongAlert(false)
    }

    const sendMailToAdmin = () => {
        let URLLINK = process.env.REACT_APP_HOST_UI + "/approval/" + formValues.First_Name + '/' + formValues.Last_Name + '/' + formValues.Email_Address.toLowerCase()
        let body = "User " + formValues.First_Name + " " + formValues.Last_Name + " has requested access to Nambri, Please click on the following link to approve.\n " + URLLINK.link(process.env.REACT_APP_HOST_UI + "/approval/" + encodeURIComponent(formValues.First_Name) + '/' + encodeURIComponent(formValues.Last_Name) + '/' + encodeURIComponent(formValues.Email_Address.toLowerCase()))
        // console.log("body is: ", body)
        try {
            let url = sendEmail

            let str = JSON.stringify({
                subject: "New User Approval Request",
                body: body,
                recipient: AdminEmail_Address.toLowerCase()
            })
            let Strbody = ReadyAPIBody(str);
            var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
            var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

            fetch(url, {
                headers: BasicAuth,
                method: 'POST',
                headers1: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: EncriptionBody
            })
                .then((response) => response.json())
                .then((response) => {
                })
                .catch((error) => {
                    console.log("error in sendMailToAdmin: ", error);
                    setAlertWrongMsg("Server is unreachable, Please try after sometime");
                    setShowWrongAlert(true)
                    setisClickedOnSubmit(false)
                })
        }
        catch (err) {
            console.log("error in sendMailToAdmin1: ", err)
        }
    }


    useEffect(() => {

        if (oneTimeExeForVideo) {
            setOneTimeExeForVideo(false)
            try {
                var credentials = window.btoa(process.env.REACT_APP_AuthenticationName + ":" + process.env.REACT_APP_AuthenticationPassword);
                var auth = { "Authorization": `Basic ${credentials}` };
                fetch(configData, {
                    headers: BasicAuth,
                })
                    .then((response) => response.json())
                    .then((response) => {
                        if (response.status !== 404) {
                            setVideoUrl(response[0].videopath)
                            setAdminEmaailAddress(response[0].adminemail.toLowerCase())
                        }
                    })
                    .catch((err) => {
                        console.log("error in useEffect in reqAcc: ", err)
                        setAlertWrongMsg("Server is unreachable, Please try after sometime");
                        setShowWrongAlert(true)
                        setisClickedOnSubmit(false)
                    })
            }
            catch (err) {
                console.log("error in useEffect in reqAcc1: ", err)
            }
        }


        if (oneTimeExe) {
            setOneTimeExe(false)
            if (Object.keys(formErrors).length === 0 && isSubmit) {
                try {
                    setisClickedOnSubmit(true)

                    let str = JSON.stringify({
                        fname: formValues.First_Name,
                        lname: formValues.Last_Name,
                        email: formValues.Email_Address.toLowerCase(),
                        phone: formValues.Phone_Number,
                        company: formValues.Company_Name,
                        role: formValues.Role,
                        status: "Inactive",
                        createdate: moment(new Date()),
                        updatedate: moment(new Date())
                    })

                    let Strbody = ReadyAPIBody(str);
                    var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
                    var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

                    fetch(createUser, {
                        headers: BasicAuth,
                        method: 'POST',
                        headers1: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: EncriptionBody
                    })
                        .then((response) => response.json())
                        .then((response) => {
                            if (response === 0) {
                                setShowWrongAlert(false)
                                setAlertMsg("Your request has been forwarded to the admin for approval");
                                setShowAlert(true)
                                sendMailToAdmin()
                                setisClickedOnSubmit(false)
                            }
                            else if (response === -1) {
                                setAlertWrongMsg("Something went wrong please try after some time");
                                setShowWrongAlert(true)
                                setisClickedOnSubmit(false)
                            }
                            else if (response === -2) {
                                setAlertWrongMsg("User email address already exist");
                                setShowWrongAlert(true)
                                setisClickedOnSubmit(false)
                            }
                        })
                        .catch((error) => {
                            console.log("error in useEffect inside reqAcc: ", error)
                            setAlertWrongMsg("Server is unreachable, Please try after sometime");
                            setShowWrongAlert(true)
                            setisClickedOnSubmit(false)
                        }
                        )
                }
                catch (error) {
                    console.log("error in useEffect inside reqAcc1: ", error)
                    setAlertWrongMsg("Something went wrong, Please try after sometime");
                    setShowWrongAlert(true)
                    setisClickedOnSubmit(false)
                }
            }
        }
    }, [formErrors, formValues.Company_Name, formValues.Email_Address, formValues.First_Name, formValues.Last_Name, formValues.Phone_Number, formValues.Role, isSubmit, navigate, oneTimeExe])


    return (
        <div className="w-100vw h-100vh row m-0">
            <div
                className="col-sm-12 col-md-6 mnh-50vh pt-5 text-light fw-500 fs-5 "
                id="loginLayout-img-ReqAccess">
                <div className="w-100" id="p1_access">
                    <div style={{ backgroundColor: '#304D8B', opacity: "64%", color: 'white' }}> Nambri is an easy-to-use data extraction software that automatically converts business taxforms into Excel.</div>
                </div><br></br>
                <div className="embed-responsive embed-responsive-16by9 d-flex justify-content-center">
                    <ReactPlayer
                        className='react-player embed-responsive-item w-100 h-100 border border-4 video_reqaccess'
                        id="vidplayeraccess"
                        url={videoUrl}
                        controls={true}
                    />
                </div><br></br>
                <div className="w-100" id="p1_access">
                    <div style={{ backgroundColor: '#1E2955', opacity: "60%", color: 'white' }}>
                        If you have to copy data from tax documents to Excel,you can
                        save hours of time and reduce errors by automating the process.
                        Nambri is an easy-to-use and secure tool that supports 1065,1120S,
                        and 1120 taxforms.
                    </div>
                </div> <br></br>
                <div id="p1_access">
                    <div style={{ backgroundColor: '#1E2955', opacity: "60%", color: 'white' }}>Nambri process is simple: <br></br>
                        <span style={{ paddingLeft: '30px' }}>1.Upload the taxforms.</span> <br></br>
                        <span style={{ paddingLeft: '30px' }}>2.Nambri extracts the taxform data.</span><br></br>
                        <span style={{ paddingLeft: '30px' }}>3.Export the data to a CSV and XSLX spreadsheet.</span>
                    </div></div>
                {/* <div className='sub-head'>
                        <div id='p2_Demo' style={{backgroundColor:'#1E2955',opacity:".75"}}>1.Upload the taxforms.</div>
                        <div id='p2_Demo' style={{backgroundColor:'#1E2955',opacity:".75"}}>2.Nambri extracts the taxform data.</div>
                        <div id='p2_Demo' style={{backgroundColor:'#1E2955',opacity:".75"}}>3.Export the data to a CSV and XSLX spreadsheet.</div>
                </div> */}
            </div>

            <div className="col-sm-12 col-md-6 py-5 ">
                <div className="col-12   d-flex justify-content-center w-100" >
                    <div className="logo-reqAccess"></div>
                </div>
                <div className="col-12 fs-2 heading" id="thanks" style={{ marginLeft: '5%' }}>Thanks for choosing us</div>  <br />
                {
                    showAlert ? <div className="alert alert-success" id="alertStyle">{alertMsg}</div> : null
                }
                {
                    showWrongAlert ? <div className="alert alert-danger" id="alertStyle">{alertWrongMsg}</div> : null
                }
                <form ref={form} onSubmit={submitSuccess} style={{ padding: '2%' }} id="formfamily">
                    <div className="row spacing" >
                        <div className="col-md-5">
                            <label htmlFor="First Name" className="form-label">
                                First Name <span style={{ color: '#F68324' }}>*</span>
                            </label>
                            <input
                                maxLength={100}
                                placeholder="First Name"
                                className="form-control width1"
                                style={{ lineHeight: '2.5' }}
                                id="firstName"
                                name='First_Name'
                                value={formValues.First_Name}
                                onChange={setInputValues}
                            />
                            <p id="red">{formErrors.First_Name}</p>
                            <label htmlFor="compnay" className="form-label">
                                Company Name <span style={{ color: '#F68324' }}>*</span>
                            </label>
                            <input
                                maxLength={150}
                                placeholder="Company Name"
                                className="form-control"
                                style={{ lineHeight: '2.5' }}
                                id="company"
                                name='Company_Name'
                                value={formValues.Company_Name}
                                onChange={setInputValues}
                            />
                            <p id="red">{formErrors.Company_Name}</p>
                            <label htmlFor="phone" className="form-label">
                                Phone Number
                            </label>
                            <input
                                maxLength={20}
                                className="form-control"
                                style={{ lineHeight: '2.5' }}
                                id="phone"
                                // type="number"
                                placeholder="Phone Number"
                                name='Phone_Number'
                                value={formValues.Phone_Number}
                                onChange={setInputValues}
                            />
                            <p id="red">{formErrors.Phone_Number}</p>
                            <p></p>
                            <br></br>
                            <button className="text-light fw-bold btn btn-warning px-5 py-2" type="submit" id="submitButton" disabled={showAlert} style={{ background: '#F68324', lineHeight: '2.5' }}>
                                {
                                    isClickedOnSubmit ?
                                        <div className='divsection1' style={{ marginLeft: '45%' }}>
                                            <ThreeDots
                                                height="25"
                                                width="25"
                                                color='white'
                                                ariaLabel='loading'
                                            />
                                        </div>
                                        :
                                        "Submit"
                                }
                            </button>
                            {/* <button className="text-light fw-bold btn btn-dark form-control" type="submit" id="submitButton" disabled={showAlert} style={{backgroundColor:'black' ,marginTop:'10px' }}>
                            Back to login
                            </button> */}
                            <div className="col-md-12 d-flex justify-content-center" style={{ marginTop: '15px' }}><Link to="/"><i className="fa-solid fa-circle-arrow-left"></i> Back to Login</Link></div>
                        </div>

                        <div className="col-md-5">
                            <label htmlFor="Last Name" className="form-label">
                                Last Name <span style={{ color: '#F68324' }}>*</span>
                            </label>
                            <input
                                maxLength={100}
                                placeholder="Last Name"
                                className="form-control"
                                style={{ lineHeight: '2.5' }}
                                id="lastName"
                                name='Last_Name'
                                value={formValues.Last_Name}
                                onChange={setInputValues}
                            />
                            <p id="red">{formErrors.Last_Name}</p>
                            <label htmlFor="role" className="form-label">
                                Role <span style={{ color: '#F68324' }}>*</span>
                            </label>
                            <input
                                maxLength={100}
                                placeholder="Role"
                                className="form-control"
                                style={{ lineHeight: '2.5' }}
                                id="role"
                                name='Role'
                                value={formValues.Role}
                                onChange={setInputValues}
                            />
                            <p id="red">{formErrors.Role}</p>
                            <label htmlFor="email" className="form-label">
                                Email Address <span style={{ color: '#F68324' }}>*</span>
                            </label>
                            <input
                                maxLength={100}
                                type="email"
                                placeholder="Email Address"
                                className="form-control"
                                style={{ lineHeight: '2.5' }}
                                id="email"
                                name='Email_Address'
                                value={formValues.Email_Address}
                                onChange={setInputValues}
                            />
                            <p id="red">{formErrors.Email_Address}</p>

                        </div>

                    </div>
                    {/* <div className="col-md-6"> */}

                    {/* <div className="col-md-4"   style={{float:'right'}}> */}

                    {/* </div> */}
                    {/* </div> */}


                    <div>

                    </div>
                </form>
            </div>
        </div>

    );
};
export default RequestAccessScreen;

