import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import { Table } from 'antd';
import { Card } from 'react-bootstrap';
import './Approval.css';
import CryptoJS from 'crypto-js';
import { BasicAuth, getUserDetails, updateUserDetails, newUserRegisterInLdap, sendEmail, externalLink, ReadyAPIBody } from '../../BusinessLogic/JavaApis/JavaApi';
function ApprovalScreen(props) {
    const params = useParams()
    const [data, setData] = React.useState([])
    const [oneTime, setOneTime] = React.useState(true)
    const [userId, setUserId] = React.useState(null)
    const [alertMsg, setAlertMsg] = React.useState('')
    const [alertFailedMsg, setAlertFailedMsg] = React.useState('')
    const [alertFailedBoolean, setAlertFailedBoolean] = React.useState(false)
    const [alertBoolean, setAlertBoolean] = React.useState(false)

    const columns = [
        {
            title: 'User Name',
            dataIndex: 'fname',
            render: (text, record) => {
                return <span style={{ fontWeight: '500' }}>{text}</span>
            }
        },
        {
            title: 'Last Name',
            dataIndex: 'lname',
            render: (text, record) => {
                return <span style={{ fontWeight: '500' }}>{text}</span>
            }
        },
        {
            title: 'Company Name',
            dataIndex: 'company',
            render: (text, record) => {
                return <span style={{ fontWeight: '500' }}>{text}</span>
            }
        },
        {
            title: 'Role',
            dataIndex: 'role',
            render: (text, record) => {
                return <span style={{ fontWeight: '500' }}>{text}</span>
            }
        },
        {
            title: 'Phone Number',
            dataIndex: 'phone',
            render: (text, record) => {
                return <span style={{ fontWeight: '500' }}>{text}</span>
            }
        },
        {
            title: 'Email Address',
            dataIndex: 'email',
            render: (text, record) => {
                return <span style={{ fontWeight: '500' }}>{text}</span>
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => {
                return <div>
                    {
                        text === "Active" ?
                            <span style={{ fontWeight: '500', color: 'teal' }}>{text}</span> :
                            <span style={{ fontWeight: '500', color: 'red' }}>{text}</span>
                    }
                </div>
            }
        },
        {
            title: 'Action',
            render: (text, record) => {
                return <div style={{ flexDirection: 'row' }}>
                    <button className="btn btn-success" disabled={alertBoolean} onClick={() => approveClicked()} style={{ fontWeight: '500', marginRight: '10%', width: '120px' }}>Approve</button>
                    <button className="btn btn-danger" onClick={() => {
                        setAlertBoolean(false)
                        setAlertFailedMsg("Rejected user approval")
                        setAlertFailedBoolean(true)
                        setTimeout(window.close, 2500);
                    }
                    }
                        style={{ fontWeight: '500', width: '120px' }}>Deny</button>
                </div>
            }
        },
    ]
    // console.log(params)
    useEffect(() => {
        try {
            if (oneTime === true) {
                setOneTime(false)

                let str = JSON.stringify({
                    username: params.userEmail
                })
                let Strbody = ReadyAPIBody(str);
                var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
                var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

                const url = getUserDetails + encodeURIComponent(EncriptionBody)
                fetch(url, {
                    headers: BasicAuth,
                })
                    .then((response) => response.json())
                    .then((response) => {
                        setData(response)
                        setUserId(response[0].id)
                    })
                    .catch((error) => {
                        console.log("error in useEffect createProject: ", error)
                        setAlertFailedBoolean(true)
                        setAlertMsg("Server is unreachable, Please try after sometime")
                    })
            }
        }
        catch (error) {
            console.log("error in useEffect createProject1: ", error)
        }
    }, [oneTime, params.userEmail]);

    const sendMail = (password) => {
        try {
            let body = "Dear " + data[0].fname + "\nYour User name and Password is\n\nUser Name: " + data[0].email + "\nPassword: " + password + "\n\nPlease click below link to login\n" + externalLink + "\n\nThanks,\nTeam Nambri"

            let str = JSON.stringify({
                subject: "Dear " + data[0].fname,
                body: body,
                recipient: data[0].email
            })
            let Strbody = ReadyAPIBody(str);
            var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
            var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

            let url = sendEmail
            fetch(url, {
                method: 'POST',
                headers: BasicAuth,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                body: EncriptionBody
            })
                .then((response) => response.json())
                .then((response) => {
                    // console.log("response in sendMailToAdmin: ", response);
                })
                .catch((error) => {
                    console.log("response in sendMailToAdmin1: ", error)
                    setAlertFailedBoolean(true)
                    setAlertMsg("Server is unreachable, Please try after sometime")
                })
        }
        catch (err) {
            console.log("error in sendMail: ", err);
        }
    }

    const updateInDbActiveState = async () => {
        try {
            let str = JSON.stringify({
                email: params.userEmail,
                status: "Active",
                userid: userId
            })
            let Strbody = ReadyAPIBody(str);
            var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
            var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

            // let url = updateUserDetails + userId
            let url = updateUserDetails
            await fetch(url, {
                method: 'PUT',
                headers: BasicAuth,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                body: EncriptionBody
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response === 0) {
                        setAlertMsg("The user credentials are sent to " + params.userEmail)
                        setAlertBoolean(true)
                        setOneTime(true)
                    } else {
                        alertFailedBoolean("Something went wrong please try after sometime,db")
                        setAlertFailedBoolean(false)
                    }
                })
                .catch((error) => {
                    console.log("response in sendMailToAdmin1: ", error)
                    setAlertFailedBoolean(true)
                    setAlertMsg("Server is unreachable, Please try after sometime")
                })
        }
        catch (err) {
            console.log("error in updateInDbActiveState: ", err);
        }
    }

    const approveClicked = async () => {
        try {
            var random_password = Math.random().toString(36).slice(-8);

            let str = JSON.stringify({
                firstname: data[0].fname,
                lastname: data[0].lname,
                username: data[0].email,
                password: random_password
            })
            let Strbody = ReadyAPIBody(str);
            var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
            var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

            let url = newUserRegisterInLdap
            await fetch(url, {
                method: 'POST',
                headers: BasicAuth,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                body: EncriptionBody
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response === 0) {
                        updateInDbActiveState()
                        sendMail(random_password)
                    }
                    else if (response === -1) {
                        setAlertFailedMsg("Something went wrong please try after sometime,ldap")
                        setAlertFailedBoolean(true)
                    }
                    else if (response === -2) {
                        setAlertFailedMsg("This User ID is already exist in ldap")
                        setAlertFailedBoolean(true)
                    }
                })
                .catch((error) => {
                    console.log("response in approveClicked: ", error)
                    setAlertFailedBoolean(true)
                    setAlertMsg("Server is unreachable, Please try after sometime")
                })
        }
        catch (err) {
            console.log("error in approveClicked1: ", err);
        }
    }


    return (
        <>
            <div className="row" style={{ margin: '0%' }}>
                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 max-auto" >
                    <div style={{ width: '100%', marginTop: '0%' }}>

                        <Card className="cardInApp">
                            {
                                alertBoolean ?
                                    <div className="alert alert-success" style={{ fontSize: '18', fontWeight: '600', padding: 10, margin: 0, border: '1px solid teal', borderRadius: 2 }}>{alertMsg}</div>
                                    : null
                            }
                            {
                                alertFailedBoolean ?
                                    <div className="alert alert-danger" style={{ fontSize: '18', fontWeight: '600', padding: 10, margin: 0, border: '1px solid red', borderRadius: 2 }}>{alertFailedMsg}</div>
                                    :
                                    null
                            }
                            <div style={{ backgroundColor: '#EAEDED', borderTopLeftRadius: '5px', borderTopRightRadius: '5px' }}>
                                <h4 style={{ margin: '2%', color: '#006699' }}>Nambri New User Approval</h4>
                                {/* <hr/> */}
                            </div>
                            <Table
                                columns={columns}
                                dataSource={data}
                                pagination={false}
                                rowKey="id"
                            />
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
}
export default ApprovalScreen;