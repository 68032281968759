import React, { Component } from "react";
import './RequestDemo.css'
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { BasicAuth, getUserDetails, configData, loginCheck, ReadyAPIBody } from '../../BusinessLogic/JavaApis/JavaApi';
import ReactPlayer from 'react-player';
import { ThreeDots } from 'react-loader-spinner'
import { timeToLogOut } from '../../BusinessLogic/setTimeOut';

import CryptoJS from 'crypto-js';

class RequestDemoScreen1 extends Component {
    constructor(props) {
        super(props)
        this.state = {
            userName_or_gmail: localStorage.getItem('temp_userName') === null ? '' : localStorage.getItem('temp_userName'),
            password: '',
            isEmpField: false,
            alertMsg: '',
            videoUrl: '',
            lastLogin: '',
            isClickedOnSignIn: false,
            timeout: timeToLogOut * 60 * 1000,
            isTimedOut: false
        }
        this.handleChangeName = this.handleChangeName.bind(this);
        this.handleChangePass = this.handleChangePass.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.forgetPassClicked = this.forgetPassClicked.bind(this);

        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        // this.codes = getCachedData()
    }

    _onAction(e) {
        // console.log('user did something', e)
        this.setState({ isTimedOut: false })
    }

    _onActive(e) {
        // console.log('user is active', e)
        this.setState({ isTimedOut: false })
    }

    _onIdle(e) {
        // console.log('user is idle', e)
        const isTimedOut = this.state.isTimedOut
        if (isTimedOut) {
            window.location.reload(true);
        } else {
            this.idleTimer.reset();
            this.setState({ isTimedOut: true })
        }

    }


    handleSubmit(e) {
        e.preventDefault();
        // console.log("one time exe in req demo")
        this.setState({ isClickedOnSignIn: true })
        // userName_or_gmail=userName_or_gmail.trim();
        // console.log("name=",this.state.userName_or_gmail)
        this.state.userName_or_gmail = this.state.userName_or_gmail.trim();
        if (this.state.userName_or_gmail.length !== 0 && this.state.password.length !== 0) {
            try {

                let str = JSON.stringify({
                    username: this.state.userName_or_gmail.toLowerCase(),
                    password: this.state.password
                })
                let Strbody = ReadyAPIBody(str);
                var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
                var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

                fetch(loginCheck, {
                    headers: BasicAuth,
                    method: 'POST',
                    headers1: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    },
                    body: EncriptionBody
                })
                    .then((response) => response.json())
                    .then((response) => {
                        // console.log("response in here: ", response);
                        if (response === 0) {
                            localStorage.setItem('my-key', this.state.userName_or_gmail.toLowerCase());
                            localStorage.setItem('temp_userName', this.state.userName_or_gmail.toLocaleLowerCase())
                            localStorage.setItem('userCred', this.state.password)
                            localStorage.setItem('Nambri_user_name_is', this.state.userName_or_gmail.toLocaleLowerCase())
                            this.getUserId(this.state.userName_or_gmail.toLocaleLowerCase())
                        }
                        else if (response === -2) {
                            this.setState({ isClickedOnSignIn: false })
                            this.setState({ alertMsg: 'Invalid username or password', isEmpField: true })
                        }
                        else if (response === -1) {
                            this.setState({ isClickedOnSignIn: false })
                            this.setState({ alertMsg: 'Something went wrong, Please try after some time', isEmpField: true })
                        }

                    })
                    .catch((error) => {
                        this.setState({ isClickedOnSignIn: false })
                        console.log("error in loginCheck api: ", error)
                        this.setState({ isEmpField: true, alertMsg: 'Server is unreachable, Please try after sometime' })
                        window.location.reload(true);
                    })
            }
            catch (error) {
                console.log("error in loginCheck1 api: ", error)
                this.setState({ isClickedOnSignIn: false })
            }
        }
        else {
            this.setState({ alertMsg: 'Username or Password should not be empty', isEmpField: true, isClickedOnSignIn: false })
        }
    }
    async getUserId(userName) {
        try {
            let str = JSON.stringify({
                username: userName
            })
            let Strbody = ReadyAPIBody(str);
            var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
            var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

            const url = getUserDetails + encodeURIComponent(EncriptionBody)
            await fetch(url, {
                headers: BasicAuth,
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response.length !== 0) {
                        localStorage.setItem('user-id', response[0].id)
                        const dateLastLoginFixed = new Date("2022-01-01 00:00:00.0");
                        // const userLastLoginDate = new Date("2022-01-01 00:00:00.0");   // comment this line
                        const userLastLoginDate = new Date(response[0].lastlogin)    // uncomment this line

                        // console.log("hard coded date: ", dateLastLoginFixed)
                        // console.log("hard coded user login date: ", userLastLoginDate)
                        if (userLastLoginDate <= dateLastLoginFixed) {
                            this.setState({ isClickedOnSignIn: false })
                            this.props.navigate('/firstlogin')
                        }
                        else {
                            this.setState({ isClickedOnSignIn: false })
                            this.props.navigate('/createproject')   // make it '/createproject'
                        }
                    }
                })
                .catch(err => {
                    console.log("error in getUserId function: ", err)
                    this.setState({ isClickedOnSignIn: false })
                    this.setState({ isEmpField: true, alertMsg: 'Server is unreachable, Please try after sometime' })
                })
        }
        catch (e) {
            console.log("error in getUserId1 function: ", e)
        }
    }
    handleChangeName(event) {
        // event.target.value=event.target.value.trim();
        // console.log("input tag")
        this.setState({ isEmpField: false })
        this.setState({ userName_or_gmail: event.target.value });
    }
    handleChangePass(event) {
        this.setState({ isEmpField: false })
        this.setState({ password: event.target.value });
    }
    forgetPassClicked() {
        localStorage.setItem('temp_userName', this.state.userName_or_gmail)
        //  console.log("sending",'temp_userName', this.state.userName_or_gmail)
        this.props.navigate('/forgot')
    }
    componentDidMount() {
        try {
            fetch(configData, {
                headers: BasicAuth,
                method: 'GET',
                headers1: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
                .then((response) => response.json())
                .then((response) => {
                    // console.log("response video", response);
                    if (response.status !== 404) {
                        this.setState({ videoUrl: response[0].videopath })
                    }
                })
                .catch((error) => {
                    console.log("error in componentDidMount in reqDemo: ", error)
                    this.setState({ isClickedOnSignIn: false })
                    this.setState({ isEmpField: true, alertMsg: 'Server is unreachable, Please try after sometime' })
                })
        }
        catch (err) {
            console.log("error in componentDidMount in reqDemo1: ", err)
        }
    }
    myPassword() {
        var x = document.getElementById("password1");
        if (x.type === "password") {
            x.type = "text";
        } else {
            x.type = "password";
        }
    }
    render() {
        return (
            <div className="w-100vw h-100vh row m-0" style={{ backgroundColor: '#FFFFFF' }}>
                <div
                    className="col-sm-12 col-md-6 mnh-50vh pt-5 text-light fw-500 fs-5 overlay"
                    id="loginLayout-img-Demo">
                    {/* <div className="wrapper"/> */}
                    <div className="w-100" id="p1_Demo">
                        <div style={{ backgroundColor: '#304D8B', opacity: "64%", color: 'white' }}> Nambri is an easy-to-use data extraction software that automatically converts business taxforms into Excel.</div>
                    </div><br></br>
                    <div className="embed-responsive embed-responsive-16by9  d-flex justify-content-center">
                        <ReactPlayer
                            className='react-player embed-responsive-item w-100 h-100 border border-4  video'
                            id="vidplayer"
                            url={this.state.videoUrl}
                            width='100%'
                            height='100%'
                            controls={true}
                        />
                    </div><br></br>

                    <div className="w-100" id="p1_Demo">
                        <div style={{ backgroundColor: '#1E2955', opacity: "60%", color: 'white' }}>
                            If you have to copy data from tax documents to Excel,you can
                            save hours of time and reduce errors by automating the process.
                            Nambri is an easy-to-use and secure tool that supports 1065,1120S,
                            and 1120 taxforms.
                        </div>
                    </div>
                    <br></br>
                    <div id="p1_Demo" ><div style={{ backgroundColor: '#1E2955', opacity: "60%", color: 'white' }}>Nambri process is simple: <br></br>
                        <span style={{ paddingLeft: '30px' }}>1.Upload the taxforms.</span> <br></br>
                        <span style={{ paddingLeft: '30px' }}>2.Nambri extracts the taxform data.</span><br></br>
                        <span style={{ paddingLeft: '30px' }}>3.Export the data to a CSV and XSLX spreadsheet.</span>
                    </div></div>
                    {/* <div className='sub-head'>
                        <div id='p2_Demo' style={{ backgroundColor: '#1E2955', opacity: ".75" }}>1.Upload the taxforms.</div>
                        <div id='p2_Demo' style={{ backgroundColor: '#1E2955', opacity: ".75" }}>2.Nambri extracts the taxform data.</div>
                        <div id='p2_Demo' style={{ backgroundColor: '#1E2955', opacity: ".75" }}>3.Export the data to a CSV and XSLX spreadsheet.</div>
                    </div> */}
                </div>


                <div className="col-sm-12 col-md-6 py-5">
                    <div className="col-12   d-flex justify-content-center w-100">
                        <div className="logo-Demo"></div>
                    </div>

                    <div className="col-10 row m-0 d-flex justify-content-center">
                        <form action="" className=" row m-0 d-flex justify-content-center" onSubmit={this.handleSubmit} id='formfamily1'>
                            <div className="mb-3 col-md-7 col-12">
                                <div className="col-10 fs-1 d-flex justify-content-center" id="helloo">Hello! Welcome back</div> <br></br>
                                {
                                    this.state.isEmpField ? <div className="py-2 alert alert-danger" id="alertInDemo" style={{ width: '100%' }}>{this.state.alertMsg}</div> : null
                                }
                                <label htmlFor="First Name" className="form-label">
                                    Username
                                </label>
                                <input
                                    type="text"
                                    placeholder="Username"
                                    className="form-control"
                                    style={{ lineHeight: '2.5' }}
                                    id="username1"
                                    // autoFocus='true'
                                    aria-describedby="emailHelp"
                                    maxLength={150}
                                    defaultValue={localStorage.getItem('temp_userName')}
                                    onChange={this.handleChangeName}
                                />
                            </div>
                            <div className="mb-3 col-md-7 col-12">
                                <label htmlFor="password" className="form-label">
                                    Password
                                </label>
                                <input
                                    type="password"
                                    placeholder="Password"
                                    className="form-control"
                                    style={{ lineHeight: '2.5' }}
                                    id="password"
                                    aria-describedby="emailHelp"
                                    maxLength={20}
                                    onChange={this.handleChangePass}
                                    onPaste={(e) => {
                                        // e.preventDefault()
                                        // return false;
                                        return true;
                                    }}
                                    onCopy={(e) => {
                                        // e.preventDefault()
                                        return false;
                                    }}

                                    onCut={(e) => {
                                        // e.preventDefault()
                                        return false;
                                    }}
                                />
                            </div>


                            <div className="mb-3 col-md-8 col-12 d-flex justify-content-center mt-3">
                                <button type="submit" id="login-submit" className="text-light fw-bold btn btn-warning" style={{ width: '87%', background: '#F68324', lineHeight: '2.5' }} >
                                    {
                                        this.state.isClickedOnSignIn ?
                                            <div className='divsection' style={{ marginLeft: '45%' }}>
                                                <ThreeDots
                                                    height="25"
                                                    width="25"
                                                    color='white'
                                                    ariaLabel='loading'
                                                />
                                            </div>
                                            :
                                            "Sign In"
                                    }
                                </button>
                            </div>
                        </form>

                        <div className="d-flex justify-content-center">
                            <p className="nu">New user? <Link to="/reqaccess" id="anchor"><span id="spanid">Request a trial access</span></Link></p>
                        </div>
                        <div className="d-flex justify-content-center">
                            <p className="nu"> Forgot Password? <Link to="/forgot" onClick={this.forgetPassClicked} id="anchor">Click here</Link></p>
                            {/* <p>Forgot Password ? <span className="textDec" onClick={this.forgetPassClicked}>Click Here</span></p> */}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

function RequestDemoScreen(props) {
    let navigate = useNavigate();
    return <RequestDemoScreen1 {...props} navigate={navigate} />
}
export default RequestDemoScreen;