import React, { useEffect, useState } from "react";
import './Forgotpassword.css'
import { Link } from "react-router-dom";
import { ThreeDots } from 'react-loader-spinner'
import validator from 'validator'
import ReactPlayer from 'react-player';
import { BasicAuth, retrievepass, configData, ReadyAPIBody } from '../../BusinessLogic/JavaApis/JavaApi';
import CryptoJS from "crypto-js";

/* eslint-disable jsx-a11y/iframe-has-title */
const Forgot = () => {

  const [alertMsg, setAlertMsg] = React.useState('')
  const [showAlert, setShowAlert] = React.useState(false)
  const [showWrongAlert, setShowWrongAlert] = React.useState(false)
  const [Email_Address, setEmail_Address] = React.useState(localStorage.getItem('temp_userName') === null ? '' : localStorage.getItem('temp_userName'))
  const [isClickedOnSendPwd, setIsClickedOnSendPwd] = React.useState(false)
  const [videoUrl, setVideoUrl] = useState('')
  const form = React.useRef();
  //  console.log("localStorage.getItem('temp_userName')=",localStorage.getItem('temp_userName'))

  const getVideoUrl = () => {
    var credentials = window.btoa(process.env.REACT_APP_AuthenticationName + ":" + process.env.REACT_APP_AuthenticationPassword);
    var auth = { "Authorization": `Basic ${credentials}` };

    fetch(configData, {
      headers: BasicAuth,
    })
      .then(result => result.json())
      .then((response) => setVideoUrl(response[0].videopath))
  }


  useEffect(() => {
    getVideoUrl()

  }, [])

  const submitSuccess = (e) => {
    e.preventDefault();
    try {
      // console.log(Email_Address);
      if (Email_Address.length !== 0) {
        if (validator.isEmail(Email_Address)) {
          setIsClickedOnSendPwd(true)

          let str = JSON.stringify({
            username: Email_Address.toLocaleLowerCase()
          })
          let Strbody = ReadyAPIBody(str);
          var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
          var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

          let url = retrievepass + encodeURIComponent(EncriptionBody)
          fetch(url, {
            headers: BasicAuth,
            method: 'GET',
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          })
            .then((response) => response.json())
            .then((response) => {
              if (response === 0) {
                setAlertMsg("Login credentials have been sent to your registered Email Id");
                setShowAlert(true)
                setIsClickedOnSendPwd(false)
                setShowWrongAlert(false)
              }
              else if (response === -1) {
                setAlertMsg("This user is not registered with Nambri");
                setShowWrongAlert(true)
                setShowAlert(false)
                setIsClickedOnSendPwd(false)
              }
              else if (response === -2) {
                setAlertMsg("Error. Please try after sometime")
                setShowWrongAlert(true)
                setShowAlert(false)
                setIsClickedOnSendPwd(false)
              }
            })
            .catch((error) => {
              console.log("error in submitSuccess: ", error)
              setShowWrongAlert(true)
              setAlertMsg("Server is unreachable, Please try after sometime")
              setIsClickedOnSendPwd(false)
            })
        } else {

          setAlertMsg("Email address is invalid");
          setShowWrongAlert(true)
        }
      }
      else {
        setAlertMsg("Email address should not be empty");
        setShowWrongAlert(true)
      }

    }
    catch (err) {
      console.log("error in submitSuccess1: ", err)
    }
  }

  const setInputValues = (e) => {
    e.preventDefault();
    setEmail_Address(e.target.value)
    setShowAlert(false)
    setShowWrongAlert(false)
  }


  return (
    <div className="w-100vw h-100vh row m-0">
      <div
        className="col-sm-12 col-md-6 mnh-50vh pt-5 text-light fw-500 fs-5"
        id="loginLayout-img-forgot">
        <div className="w-100" id="p1_forgot"> <div style={{ backgroundColor: '#304D8B', opacity: "64%", color: 'white' }}> Nambri is an easy-to-use data extraction software that automatically converts business taxforms into Excel.</div>
        </div><br></br>
        <div className="embed-responsive embed-responsive-16by9 d-flex justify-content-center player-wrapper">
          <ReactPlayer
            className='react-player embed-responsive-item w-100 h-100 border border-4 video'
            id="vidforgot"
            url={videoUrl}
            width='100%'
            height='100%'
            controls={true}
          />
        </div><br></br>
        <div className="w-100" id="p1_forgot">
          <div style={{ backgroundColor: '#1E2955', opacity: "60%", color: 'white' }}>
            If you have to copy data from tax documents to Excel,you can
            save hours of time and reduce errors by automating the process.
            Nambri is an easy-to-use and secure tool that supports 1065,1120S,
            and 1120 taxforms.
          </div>
        </div><br></br>
        <div id="p1_forgot"><div style={{ backgroundColor: '#1E2955', opacity: "60%", color: 'white' }}>Nambri process is simple: <br></br>
          <span style={{ paddingLeft: '30px' }}>1.Upload the taxforms.</span> <br></br>
          <span style={{ paddingLeft: '30px' }}>2.Nambri extracts the taxform data.</span><br></br>
          <span style={{ paddingLeft: '30px' }}>3.Export the data to a CSV and XSLX spreadsheet.</span>
        </div></div>
        {/* <div className='sub-head'>
                        <div id='p2_Demo' style={{backgroundColor:'#1E2955',opacity:".75"}}>1.Upload the taxforms.</div>
                        <div id='p2_Demo' style={{backgroundColor:'#1E2955',opacity:".75"}}>2.Nambri extracts the taxform data.</div>
                        <div id='p2_Demo' style={{backgroundColor:'#1E2955',opacity:".75"}}>3.Export the data to a CSV and XSLX spreadsheet.</div>
        </div> */}
      </div>


      <div className="col-sm-12 col-md-6 py-5">
        <div className="col-12   d-flex justify-content-center w-100">
          <div className="logo-forgot"></div>
        </div>
        <form ref={form} onSubmit={submitSuccess}>

          <div className="col-12 row m-0 d-flex justify-content-center">
            <div className="mb-3 col-md-7">
              <div className="col-10 fs-2" id="retrive">Retrieve password</div> <br></br>

              {
                showAlert ? <div className="py-2 alert alert-success" id="alertStyle_forgot" style={{ width: '100%' }}>{alertMsg}</div> : null
              }
              {
                showWrongAlert ? <div className="py-2 alert alert-danger" id="alertStyle_forgot" style={{ width: '100%' }}>{alertMsg}</div> : null
              }
              <label htmlFor="Retrive password" className="form-label" id="registerid">
                Please enter your Registered User ID
              </label>
              <input
                placeholder="User ID"
                className="form-control"
                style={{ lineHeight: '2.5' }}
                id="firstName"
                aria-describedby="emailHelp"
                onChange={setInputValues}
                value={Email_Address}
              />
            </div>

            <div className="mb-3 col-md-7  d-flex justify-content-center mt-2">
              <button
                type="submit"
                id="login-submit"
                className="text-light fw-bold btn btn-warning px-5 py-1"
                disabled={isClickedOnSendPwd}
                style={{ pointerEvents: isClickedOnSendPwd === true ? 'not-allowed' : 'not-allowed', background: '#F68324', lineHeight: '2.5' }}
              >
                {
                  isClickedOnSendPwd ?
                    <div style={{ marginLeft: '45%' }}>
                      <ThreeDots
                        height="25"
                        width="25"
                        color='white'
                        ariaLabel='loading'

                      />
                    </div>
                    :
                    "Send Password"
                }
              </button>
            </div>
            <div className="col-md-12 d-flex justify-content-center" id="backto"><Link to="/"><i className="fa-solid fa-circle-arrow-left"></i> Back to sign in</Link></div>

          </div>
        </form>

      </div>


    </div>
  );
};
export default Forgot;