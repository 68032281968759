import React from 'react';
import { Link } from 'react-router-dom'
import "antd/dist/antd.min.css";
import { Table, Form, Input, Popconfirm, Dropdown, Menu, Card, Modal } from 'antd';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan, faXmark, faRefresh, faSearch, faCircleExclamation, faExclamationCircle, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { Spinner, } from 'react-bootstrap';
import OutsideClickHandler from 'react-outside-click-handler';
import axios from 'axios';
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from 'react-router';
import { ThreeDots } from 'react-loader-spinner'
import { MoreOutlined } from '@ant-design/icons'
import Header from '../Header';
import './CreateProject.css';
import CryptoJS from 'crypto-js';
import {
    BasicAuth,
    getProjects,
    createProjectUrl,
    updateProject,
    createDocument,
    getDocumentDetails,
    updateDocumentDetails,
    uploadUrl,
    enqueueUrl,
    EnqueUserPassword,
    EnqueUserName,
    createAuditDetails,
    DeletepdfFile,
    DeleteProject,
    specialCharsError,
    BasicAuth2,
    ReadyAPIBody
} from '../../BusinessLogic/JavaApis/JavaApi';

import { timeToLogOut } from '../../BusinessLogic/setTimeOut';


import ReactDOM from 'react-dom';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import placements from 'rc-tooltip/lib/placements';

function CreateProjectScreen(props) {
    let navigate = useNavigate();
    const [dataSource, setDataSource] = React.useState([])
    const [isCreateClicked, setIsCreateClicked] = React.useState(false)
    const [editingRow, setEditingRow] = React.useState(null)
    const [projectName, setProjectName] = React.useState("New Project")
    const [projectN, setProjectN] = React.useState(null)
    const [form] = Form.useForm()
    const [isDisabled, setIsDisabled] = React.useState(true)
    const [fileDataSource, setFileDataSource] = React.useState([])
    const [prjId, setPrjId] = React.useState(null)
    const [isExportClicked, setExportClicked] = React.useState(false)
    const [refresh, setRefresh] = React.useState(true)
    const [alertExpo, setAlertExpo] = React.useState(false)
    const [errorMsg, setErrorMsg] = React.useState('')
    const [errorOccured, setErrorOccured] = React.useState(false)
    const [coloredRow, setColoredRow] = React.useState(true)
    const [checkExistProject, setCheckExtProj] = React.useState('')
    const [isExportAllClicked, setIsExpClicked] = React.useState(true)
    const [allstmntchecked, setallstmntchecked] = React.useState(true)
    const [blnssheet, setblnssheet] = React.useState(true)
    const [histincome, sethistincome] = React.useState(true)
    const [expGuids, setExpGuids] = React.useState('')
    const [spinnerVisibility, setSpinnerVisibility] = React.useState(false)
    const [fileSpinner, setFileSpinner] = React.useState(false)
    const [exportbuttonon, setexportbuttonon] = React.useState(true)

    const [exportSuccAlertFlag, setExportSuccAlertFlag] = React.useState(false)
    const [exportSuccAlertMsg, setExportSuccAlertMsg] = React.useState('')
    const [duplicateFileMsg, setDuplicateFileMsg] = React.useState('')
    const [duplicateFileMsgFlag, setDuplicateFileMsgFlag] = React.useState('')
    const [time, setTime] = React.useState(timeToLogOut * 60 * 1000)

    const [checkval, setcheckval] = React.useState('');
    const [checkorg, setcheckorg] = React.useState('');

    const [isClickedOnSubmit, setIsClickedOnSubmit] = React.useState(false)

    const [Uploading, setUploading] = React.useState(false)
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [DeleteFileName, setDeleteFileName] = React.useState("");
    const [DeleteFileId, setDeleteFileId] = React.useState("");

    const [isModalOpen1, setIsModalOpen1] = React.useState(false);
    const [DeleteProjectName, setDeleteProjectName] = React.useState('');
    const [DeleteProjectID, setDeleteProjectID] = React.useState('');
    const specialChars = /[=;*]/;

    const columns = [
        {
            title: 'Projects',
            dataIndex: 'description',
            key: 'description',

            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.description.localeCompare(b.description),
            render: (text, record) => {
                if (editingRow === record.id) {
                    return <Form.Item
                        rules={[{
                            required: true,
                        }]}
                        style={{ padding: 0, margin: 0 }}
                    >
                        <OutsideClickHandler
                            onOutsideClick={() => {
                                if (projectN.length === 0) {
                                    setErrorOccured(false)
                                    onFinish("noAction")
                                } else if (projectN === checkExistProject || errorOccured === true) {
                                    onFinish("noAction")
                                    setErrorOccured(false)
                                    setProjectN(checkorg)
                                } else if (projectN.length !== 0 && projectN !== checkExistProject) {
                                    onFinish("action")
                                }
                            }}
                        >
                            <Input maxLength={100} onKeyDown={(e) => { if (e.key === 'Escape') { myFunction("EscAtProjectRename") } }} style={{ width: '90%', float: 'left', fontWeight: '600' }} onFocus={(e) => e.target.select()} autoFocus={true} defaultValue={record.description} onChange={(e) => { editprojectN(e.target.value, record.description) }} ></Input>
                            <FontAwesomeIcon icon={faXmark} onClick={() => {
                                onFinish("noAction")
                                setErrorOccured(false)
                            }} style={{ width: 'auto', height: '22px', marginTop: '3%', cursor: 'pointer', color: 'red', float: 'right' }} />
                        </OutsideClickHandler>
                    </Form.Item>
                } else {
                    return <div id='proN' style={{ width: '100%' }}><span style={{ fontWeight: '900', cursor: Uploading ? 'context-menu' : 'pointer' }} onClick={() => {
                        setPrjId(record.id)
                        setProjectN(record.description)
                        setIsDisabled(false)
                    }}>{text}</span></div>
                }

            },
            ellipsis: true,
        },


        {
            width: '2%',
            key: 'Action',
            render: (_, record) => {
                // let t = "Are you sure you want to delete \"" + record.description + "\"? It will also delete files related to " + record.description
                let t = "Are you sure you want to delete \"" + record.description + "\"?"
                const menu = (
                    <Menu style={{ width: '250px', borderRadius: '10%', textDecoration: 'white', background: 'black' }}
                        items={[
                            {
                                key: '1',
                                danger: 'true',
                                label: (
                                    <a target="_blank" rel="noopener noreferrer" onClick={() => {
                                        if (Uploading === false) {
                                            setIsCreateClicked(false)
                                            setIsDisabled(true)
                                            setEditingRow(record.id)
                                            setCheckExtProj(record.description)
                                            setcheckorg(record.description);
                                            setcheckval(record.description);
                                            form.setFieldsValue({
                                                description: record.description,
                                            })
                                        } else {
                                            // console.log("please wait until file uploading is finish")
                                        }
                                    }}>
                                        <span style={{ float: 'left', color: 'white' }}>Edit</span>
                                    </a>
                                )
                            },
                            {
                                key: '2',
                                danger: 'true',
                                label: (


                                    // <Popconfirm disabled={Uploading} title={<div style={{ fontWeight: 600, color: 'red' }}>{t}</div>} onConfirm={() => DeleteRow(record.id, record.description)}>
                                    <a target="_blank" rel="noopener noreferrer" onClick={() => {
                                        setIsDisabled(true)
                                        // console.log(record)
                                        setDeleteProjectID(record.id)
                                        setDeleteProjectName(record.description)
                                        showModal1()
                                    }}>
                                        <span style={{ float: 'left', color: 'white' }} >Delete</span>
                                        {/* 
                                    <FontAwesomeIcon icon={faTrashCan}  style={{ width: '25px', height: '20px', marginRight: '12%', cursor: 'pointer', color: '#CD5C5C', }} /> */}
                                    </a>
                                    // </Popconfirm>


                                )
                            },
                        ]}
                    />
                );
                return (
                    <>
                        <div htmlFor="file" className="" style={{ width: "100%" }}>
                            <div style={{ flexDirection: 'row' }}>
                                <div style={{ outline: 'none', float: 'right' }}>
                                    <Dropdown overlay={menu} trigger={['click']} placement="bottomRight" style={{ float: 'left' }}>
                                        <MoreOutlined />
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                    </>
                )
            }
        }
    ]
    const columns_files = [

        {
            title: 'File Name',
            dataIndex: 'filename',
            key: 'filename',


            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.filename.localeCompare(b.filename),

            render: (text, record) => {
                return <span style={{ fontWeight: '500', fontFamily: 'poppins' }}>{text}</span>
            }
        },
        {
            title: 'Date & Time',
            dataIndex: 'createdate',
            key: 'file_createdate',

            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.createdate.localeCompare(b.file_createdate),

            render: (text, record) => {
                return <span style={{ fontWeight: '500', fontFamily: 'poppins' }}>{moment(text).format('MM/DD/YYYY HH:mm')}</span>
            }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'Status',

            sortDirections: ['descend', 'ascend'],
            sorter: (a, b) => a.status.localeCompare(b.status),

            render: (text, record) => {
                return <span style={{ fontWeight: '500', fontFamily: 'poppins' }}>{text}</span>
            }
        },
        {
            title: 'Action',
            key: 'file_Action',
            render: (_, record) => {
                let t = "Are you sure you want to delete \"" + record.filename + "\" File ?"
                return (
                    <>
                        <div htmlFor="file" className="" style={{ width: "100%" }}>
                            <div style={{ flexDirection: 'row' }}>
                                {/* <Popconfirm disabled={Uploading} title={<div style={{ fontWeight: 600, color: 'red' }}>{t}</div>} onConfirm={() => DeleteFileRow(record.id, record.filename)}> */}
                                <FontAwesomeIcon icon={faTrashCan} style={{ width: '25px', height: '20px', marginRight: '25px', cursor: 'pointer', color: '#CD5C5C', }} onClick={() => {
                                    setDeleteFileName(record.filename)
                                    setDeleteFileId(record.id)
                                    // console.log("record info= ",record)
                                    showModal()
                                }} />

                                {/* </Popconfirm> */}
                                <Link to={((record.status !== "6 of 6: Document Complete") || (Uploading === true)) ? <></> : "/verification"} state={{ ProjectId: prjId, FileName: record.filename, ProjectNIs: projectN }}><FontAwesomeIcon data-tip={(record.status === "6 of 6: Document Complete") ? "Preview" : null} icon={faSearch} disabled={(record.status !== "6 of 6: Document Complete") || (Uploading === true)} style={{ width: '25px', height: '20px', cursor: (record.status !== "6 of 6: Document Complete") ? 'not-allowed' : 'pointer', color: (record.status !== "6 of 6: Document Complete") ? 'gray' : 'teal' }}
                                    onClick={() => {
                                        retainedValues(prjId, projectN, record.filename)
                                    }}
                                /></Link>
                                {
                                    record.is_discrepancy === 'true' ?
                                        <FontAwesomeIcon icon={faCircleExclamation} style={{ width: '25px', height: '20px', color: '#F27A18', marginLeft: "20px" }} />
                                        : null
                                }
                            </div>
                        </div>
                    </>
                )
            },
            ellipsis: true,
        }
    ]




    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        // console.log(DeleteFileId, DeleteFileName)
        DeleteFileRow(DeleteFileId, DeleteFileName)
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };



    const showModal1 = () => {
        setIsModalOpen1(true);
    };

    const handleOk1 = () => {
        // console.log(DeleteProjectID, DeleteProjectName)
        DeleteRow(DeleteProjectID, DeleteProjectName)
        setIsModalOpen1(false);
    };

    const handleCancel1 = () => {
        setIsModalOpen1(false);
    };



    const handleOnIdle = event => {
        localStorage.removeItem('my-key');
        localStorage.removeItem('user-id');
        localStorage.removeItem('temp_userName')
        localStorage.removeItem('userCred')
        localStorage.removeItem('project-id-is');
        localStorage.removeItem('project-name-is');
        navigate('/')
    }

    const handleOnActive = event => {
        setTime(time)
    }

    const handleOnAction = (e) => {
        setTime(time)
    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: time,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 100
    })


    const editprojectN = (e, projectNameIs) => {
        setErrorOccured(false)
        if (!e) {
            setProjectN(projectNameIs)
            setcheckval(null)
        }
        else {
            setProjectN(e.trim());
            setcheckval(e)
            setErrorOccured(false)
        }

    }

    const retainedValues = (ProjectIdIs, ProjectNameIs, fileName) => {
        localStorage.setItem('project-id-is', ProjectIdIs);
        localStorage.setItem('project-name-is', ProjectNameIs);
    }
    // const myFunction = () => {
    //     console.log("Esc clicked")
    //     setProjectN(checkorg)
    //     setIsCreateClicked(false)
    //     setErrorOccured(false)
    //     setProjectName("New Project")
    //     setIsDisabled(false)
    //     onFinish("noAction")
    // };
    const myFunction = (EscFunctionAt) => {
        if (EscFunctionAt === "EscAtProjectRename") {
            setProjectN(checkorg)
        }
        setIsCreateClicked(false)
        setErrorOccured(false)
        setProjectName("New Project")
        if (dataSource.length !== 0) {
            setIsDisabled(false)
        } else {
            setIsDisabled(true)
        }
        onFinish("noAction")
    };

    React.useEffect(() => {
        try {
            getProjectAndFileDetails()
        }
        catch (e) {
            console.log("error in createproject useEffect: ", e)
        }
    }, [refresh, checkorg, prjId, expGuids]);

    const getProjectAndFileDetails = () => {

        let str = JSON.stringify({
            project_id: localStorage.getItem('user-id')
        })
        let Strbody = ReadyAPIBody(str);
        var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
        var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

        // let url = getProjects + localStorage.getItem('user-id') + '/'
        let url = getProjects + encodeURIComponent(EncriptionBody)
        var credentials = window.btoa(process.env.REACT_APP_AuthenticationName + ":" + process.env.REACT_APP_AuthenticationPassword);
        var auth = { "Authorization": `Basic ${credentials}` };

        fetch(url, {
            headers: BasicAuth,
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.length > 0) {

                    let empResp = [].slice.call(response).sort((a, b) => (a.createdate > b.createdate) ? 1 : -1)
                    if (coloredRow === true && empResp.length !== 0) {
                        setColoredRow(false)
                        if (localStorage.getItem('project-id-is') === null && localStorage.getItem('project-name-is') === null) {
                            setPrjId(empResp[empResp.length - 1].id)
                            setProjectN(empResp[empResp.length - 1].description)
                            displayFileDetailsForProject(empResp[empResp.length - 1].id)
                        } else {
                            setPrjId(localStorage.getItem('project-id-is'))
                            setProjectN(localStorage.getItem('project-name-is'))
                            displayFileDetailsForProject(localStorage.getItem('project-id-is'))
                        }
                        setIsDisabled(false)
                    }
                    else if (coloredRow === true && empResp.length === 0) {
                        setColoredRow(false)
                        setIsDisabled(true)
                    }
                    const sortedActivities = empResp.sort((a, b) => new Date(b.createdate) - new Date(a.createdate))
                    setDataSource(sortedActivities)
                    // console.log(sortedActivities);
                    if (prjId === null) {
                        setFileDataSource([])
                        setProjectName("New Project")
                    }
                }
                else {
                    setColoredRow(false)
                    setPrjId(null)
                    setProjectN(null)
                    setIsDisabled(true)
                    setDataSource([])
                    setFileDataSource([])
                    setCheckExtProj('')
                }
            })
            .catch((error) => {
                console.log("error in useEffect createProject: ", error)
                setErrorMsg("Server is unreachable, Please try after sometime")
                setErrorOccured(true)
                setColoredRow(false)
                setFileSpinner(false)
            })

        // const keyDownHandler = event => {
        //     if (event.key === 'Escape') {
        //         event.preventDefault();
        //         myFunction();
        //     }
        // };

        // document.addEventListener('keydown', keyDownHandler);

        // return () => {
        //     document.removeEventListener('keydown', keyDownHandler);
        // };
    }

    const displayFileDetailsForProject = async (selectedProId) => {
        try {
            setFileSpinner(true)

            let str = JSON.stringify({
                projectid: selectedProId
            })
            let Strbody = ReadyAPIBody(str);
            var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
            var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

            // let url = getDocumentDetails + localStorage.getItem('user-id') + '/' + selectedProId
            let url = getDocumentDetails + encodeURIComponent(EncriptionBody) + "/"
            var credentials = window.btoa(process.env.REACT_APP_AuthenticationName + ":" + process.env.REACT_APP_AuthenticationPassword);
            var auth = { "Authorization": `Basic ${credentials}` };
            return await fetch(url, {
                headers: BasicAuth,
                method: 'GET',
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
                .then((response) => response.json())
                .then((response) => {

                    const sortedActivities = response.sort((a, b) => new Date(b.createdate) - new Date(a.createdate))
                    setFileSpinner(false)
                    setFileDataSource(sortedActivities)

                    setexportbuttonon(true)
                    response.map((data) => {
                        if (data.status === "6 of 6: Document Complete") {
                            setexportbuttonon(false)
                        }
                    });
                    return response;
                })
                .catch((error) => {
                    console.log("error in displayFileDetailsForProject: ", error)
                    setDuplicateFileMsg("Server is unreachable, Please try after sometime")
                    setDuplicateFileMsgFlag(true)
                    setColoredRow(false)
                    setFileSpinner(false)
                })
        }
        catch (e) {
            console.log("error in displayFileDetailsForProject1: ", e)
        }
    }

    const createProject = () => {
        setIsCreateClicked(true)
        setIsDisabled(true)
        setExportSuccAlertFlag(false)
        setExportClicked(false)
    }

    const checkProjectName = (event) => {
        setErrorOccured(false)
        if (event.length !== 0) {
            setProjectName(event.trim())
        }
        if (event === '') {
            setProjectName(null)
        }
    }
    const checkProjectNameExist = (proName) => {
        if (checkExistProject === proName) {
            return true
        }
        else {
            const data = [...dataSource]
            var retVal = data.some(post => {
                if (Object.values(post).indexOf(proName) > -1) {
                    return true;
                }
                else {
                    setCheckExtProj('')
                    return false;
                }
            })
        }
        return retVal;
    }
    const saveClicked = () => {
        if (projectName !== null && /^\s*$/.test(projectName) !== true) {
            // in the below line the condition was just < but changed to <=
            if (!specialChars.test(projectName)) {
                if (projectName.length <= 100) {
                    setColoredRow(true)
                    var isProjectNameExist = checkProjectNameExist(projectName)
                    if (isProjectNameExist === false) {
                        setIsCreateClicked(false)
                        try {
                            let url = createProjectUrl

                            let str = JSON.stringify({
                                user_id: localStorage.getItem('user-id'),
                                description: projectName,
                                is_visible: true,
                                createdate: moment(new Date()),
                                updatedate: moment(new Date())
                            })
                            let Strbody = ReadyAPIBody(str);
                            var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
                            var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

                            fetch(url, {
                                method: 'POST',
                                headers: BasicAuth,
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                body: EncriptionBody
                            })
                                .then((response) => response.json())
                                .then((response) => {
                                    if (response === 0) {

                                        localStorage.removeItem('project-id-is');
                                        localStorage.removeItem('project-name-is');
                                        setRefresh(!refresh)
                                        if (errorOccured !== true) {
                                            {
                                                let str = JSON.stringify({
                                                    project_id: localStorage.getItem('user-id')
                                                })
                                                let Strbody = ReadyAPIBody(str);
                                                var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
                                                var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

                                                // let url = getProjects + localStorage.getItem('user-id') + '/'
                                                let url = getProjects + encodeURIComponent(EncriptionBody)

                                                fetch(url, {
                                                    headers: BasicAuth,
                                                })
                                                    .then((response) => response.json())
                                                    .then((response) => {

                                                        response.map((data) => {
                                                            if (data.description === projectName) {
                                                                let AuditDetailsIs1 = JSON.stringify({
                                                                    user_id: localStorage.getItem('user-id'),
                                                                    project_id: data.id,
                                                                    description: "Project: " + projectName + " is created",
                                                                    updatedate: moment(new Date())
                                                                });
                                                                insertIntoAuditTbl(AuditDetailsIs1)
                                                            }
                                                        })
                                                    })
                                            }
                                        }
                                    }
                                })
                                .catch((error) => {
                                    console.log("error in saveClicked1: ", error)
                                    setErrorMsg("Server is unreachable, Please try after sometime")
                                    setErrorOccured(true)
                                    setColoredRow(false)
                                    setFileSpinner(false)
                                })
                        }
                        catch (e) {
                            console.log("error in saveClicked1: ", e)
                        }
                    }
                    else {
                        setErrorMsg("Project name already exist")
                        setErrorOccured(true)
                        setColoredRow(false)
                    }
                }
                else {
                    setErrorMsg("Project name should not more than 100 characters")
                    setErrorOccured(true)
                    setColoredRow(false)
                }
            }
            else {
                setErrorMsg(specialCharsError)
                setErrorOccured(true)
                setColoredRow(false)
            }
        }
        else {
            setErrorMsg("Project name should not be empty")
            setErrorOccured(true)
            setColoredRow(false)
        }
    }
    const insertIntoAuditTbl = (AuditDetailsIs1) => {
        try {
            let url = createAuditDetails;
            let str = AuditDetailsIs1
            let Strbody = ReadyAPIBody(str);
            var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
            var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

            fetch(url, {
                method: 'POST',
                headers: BasicAuth,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                body: EncriptionBody
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response === 0) {
                        // console.log("After insertIntoAuditTbl API")
                    }
                    else {
                        console.log("Error in resp insertIntoAuditTbl: ", response)
                    }
                })
                .catch((error) => {
                    console.log("Error in resp insertIntoAuditTbl:", error)
                })
        }
        catch (e) {
            console.log("Error in resp insertIntoAuditTbl:", e)
            if (e.response.status === 401) {
                let url = createAuditDetails;
                let str = AuditDetailsIs1
                let Strbody = ReadyAPIBody(str);
                var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
                var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

                fetch(url, {
                    method: 'POST',
                    headers: BasicAuth,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    body: EncriptionBody
                })
                    .then((response) => response.json())
                    .then((response) => {
                        if (response === 0) {

                        }
                        else {
                            console.log("Error in resp insertIntoAuditTbl: ", response)
                        }
                    })
                    .catch((error) => {
                        console.log("Error in resp insertIntoAuditTbl:", error)
                    })
            }
        }
    }

    const DeleteRow = (selectedForDelRow, deletedprojectname) => {
        try {

            let str = JSON.stringify({
                userid: localStorage.getItem('user-id'),
                projectid: selectedForDelRow
            })
            let Strbody = ReadyAPIBody(str);
            var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
            var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

            // let url = DeleteProject + localStorage.getItem('user-id') + '/' + selectedForDelRow
            let url = DeleteProject + encodeURIComponent(EncriptionBody) + "/"

            var credentials = window.btoa(process.env.REACT_APP_AuthenticationName + ":" + process.env.REACT_APP_AuthenticationPassword);
            var auth = { "Authorization": `Basic ${credentials}` };

            fetch(url, {
                method: 'DELETE',
                headers: BasicAuth,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            })
                .then((response) => response.json())
                .then((response) => {
                    if (response === 0) {

                        localStorage.removeItem('project-id-is');
                        localStorage.removeItem('project-name-is');
                        setProjectN(null)
                        setColoredRow(true)
                        setPrjId(null)
                        setRefresh(!refresh)
                        // console.log(dataSource)
                        let AuditDetailsIs1 = JSON.stringify({
                            user_id: localStorage.getItem('user-id'),
                            project_id: prjId,
                            description: "Project: " + deletedprojectname + " is deleted",
                            updatedate: moment(new Date())
                        });
                        insertIntoAuditTbl(AuditDetailsIs1)
                    }
                })
                .catch((error) => {
                    console.log("error in DeleteRow: ", error)
                    setErrorMsg("Server is unreachable, Please try after sometime")
                    setErrorOccured(true)
                    setColoredRow(false)
                    setFileSpinner(false)
                })
        }
        catch (e) {
            console.log("error in DeleteRow1: ", e)
        }
    }
    const DeleteFileRow = (fileId, fName) => {
        try {
            let str = JSON.stringify({
                userid: localStorage.getItem('user-id'),
                projectid: prjId,
                documentid: fileId,
                filename: fName
            })
            let Strbody = ReadyAPIBody(str);
            var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
            var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

            // let url = DeletepdfFile + localStorage.getItem('user-id') + '/' + prjId + '/' + fileId + '/' + encodeURIComponent(fName)
            let url = DeletepdfFile + encodeURIComponent(EncriptionBody) + "/"

            var credentials = window.btoa(process.env.REACT_APP_AuthenticationName + ":" + process.env.REACT_APP_AuthenticationPassword);
            var auth = { "Authorization": `Basic ${credentials}` };
            fetch(url, {
                method: 'DELETE',
                headers: BasicAuth,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            })
                .then((response) => response.json())
                .then((response) => {
                    let AuditDetailsIs1 = JSON.stringify({
                        user_id: localStorage.getItem('user-id'),
                        project_id: prjId,
                        document_id: fileId,
                        description: "File " + fName + " is Deleted",
                        updatedate: moment(new Date())
                    });
                    insertIntoAuditTbl(AuditDetailsIs1)
                    displayFileDetailsForProject(prjId)
                })
                .catch((error) => {
                    console.log("error in DeleteFileRow: ", error)
                    setDuplicateFileMsg("Server is unreachable, Please try after sometime")
                    setDuplicateFileMsgFlag(true)
                    setColoredRow(false)
                    setFileSpinner(false)
                })
        }
        catch (e) {
            console.log("error in DeleteFileRow1: ", e)
        }
    }
    const onFinish = (values) => {
        if (values === "noAction") {
            setEditingRow(null)
        }
        else if (values === "action") {
            if (checkval != null) {
                if (projectN !== null && /^\s*$/.test(projectN) !== true) {
                    if (!specialChars.test(projectN)) {
                        if (checkProjectNameExist(projectN) === false) {
                            try {
                                setColoredRow(true)

                                let str = JSON.stringify({
                                    updatedate: moment(new Date()),
                                    description: projectN,
                                    editing_row: editingRow
                                })
                                let Strbody = ReadyAPIBody(str);
                                var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
                                var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

                                // let url = updateProject + '/' + editingRow   // editing row nothing but project id
                                let url = updateProject

                                fetch(url, {
                                    method: 'PUT',
                                    headers:
                                        BasicAuth,
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    body: EncriptionBody
                                })
                                    .then((response) => response.json())
                                    .then((response) => {
                                        if (response === 0) {
                                            setErrorOccured(false)
                                            setPrjId(prjId)
                                            setRefresh(!refresh)
                                            setEditingRow(null)
                                            setColoredRow(false)
                                            let AuditDetailsIs1 = JSON.stringify({
                                                user_id: localStorage.getItem('user-id'),
                                                project_id: prjId,
                                                description: "Project: " + checkExistProject + " is Renamed to " + projectN,
                                                updatedate: moment(new Date())
                                            });
                                            insertIntoAuditTbl(AuditDetailsIs1)
                                        } else {
                                            setErrorMsg("Something went wrong,Please try after some time")
                                            setErrorOccured(true)
                                        }
                                    })
                                    .catch((err) => {
                                        console.log("error in onFinish: ", err)
                                        setErrorMsg("Server is unreachable, Please try after sometime")
                                        setErrorOccured(true)
                                        setColoredRow(false)
                                        setFileSpinner(false)
                                    })
                            }
                            catch (e) {
                                console.log("error in onFinish1: ", e)
                                setColoredRow(false)
                            }
                        }
                        else {
                            setErrorOccured(true)
                            setErrorMsg("Project name already exists")
                            setColoredRow(false)
                        }
                    } else {
                        setErrorMsg(specialCharsError)
                        setErrorOccured(true)
                        setColoredRow(false)
                    }
                }
                else {
                    setErrorMsg("Project name should not be empty")
                    setErrorOccured(true)
                    setColoredRow(false)
                }

            }
            else {
                setErrorOccured(true)
                setErrorMsg("Project name should not be empty")
                setColoredRow(false)
            }
        }
    }

    const isObject = (variable) => {
        if (typeof variable !== "string") {
            return false;
        }

        try {
            const parsedValue = JSON.parse(variable);
            return typeof parsedValue === "object" && parsedValue !== null && Object.prototype.toString.call(parsedValue) === "[object Object]";
        } catch (error) {
            return false;
        }
    }

    const getGuid = async (fileName) => {
        // try {
        //     console.log("Before enqueueUrl API")
        //     let url = enqueueUrl + localStorage.getItem('user-id') + '/' + prjId + '/' + encodeURIComponent(fileName)
        //     const data = await axios.get(url, {
        //         auth: {
        //             username: EnqueUserName,
        //             password: EnqueUserPassword,
        //         },
        //         withCredentials: true
        //     })
        //     console.log("After enqueueUrl API", data.data)
        //     return data.data
        // }
        // catch (error) {
        //     console.log("error is: ", error)
        //     if (error.response.status === 401) {
        //         console.log("Before enqueueUrl API at catch")
        //         let url = enqueueUrl + localStorage.getItem('user-id') + '/' + prjId + '/' + encodeURIComponent(fileName)
        //         const data = await axios.get(url, {
        //             auth: {
        //                 username: EnqueUserName,
        //                 password: EnqueUserPassword,
        //             },
        //             withCredentials: true
        //         })
        //         console.log("After enqueueUrl API at catch", data.data)
        //         return data.data
        //     }
        // }

        try {
            var GuidData;
            let API_URL = enqueueUrl + localStorage.getItem('user-id') + '/' + prjId + '/' + encodeURIComponent(fileName)
            await fetch(API_URL, { headers: BasicAuth2, })
                .then((response => response.text()))
                .then(async (response) => {
                    if (isObject(response)) {
                        response = JSON.parse(response);
                        if (response.status == 401) {
                            // recall again if first basic auth fail 
                            await fetch(API_URL, { headers: BasicAuth2, })
                                .then((response => response.text()))
                                .then((response) => {
                                    if (!isObject(response)) {
                                        // return response;
                                        GuidData = response;
                                    }
                                })
                        }
                    } else {
                        // return response;
                        GuidData = response;
                    }
                })
                .catch(error => console.log("error", error));

            return GuidData;

        } catch (error) {
            console.log("enqueue API error is: ", error);
        }

    }
    const checkIsFileExist = (fileName) => {
        for (let i = 0; i < fileDataSource.length; i++) {
            if (fileDataSource[i].filename === fileName) {
                return true
            }
        }
        return false
    }

    const selectedFiles = async (e) => {
        setSpinnerVisibility(true)
        try {
            let msg = ""
            setIsDisabled(true)
            setUploading(true);
            setFileSpinner(true)
            setexportbuttonon(true);
            for (var i = 0; i < e.target.files.length; i++) {
                // if (checkIsFileExist(e.target.files[i].name.replace("[", "").replace("]", "").replace("{", "").replace("}", "")) === true) {
                    if (checkIsFileExist(e.target.files[i].name) === true) {
                    if (e.target.files.length === 1) {
                        // msg += e.target.files[i].name.replace("[", "").replace("]", "").replace("{", "").replace("}", "")
                        msg += e.target.files[i].name
                    } else {
                        // msg += e.target.files[i].name.replace("[", "").replace("]", "").replace("{", "").replace("}", "") + " , "
                        msg += e.target.files[i].name + " , "
                    }
                } else {
                    const formData = new FormData();
                    formData.append('file', e.target.files[i]);

                    let str = JSON.stringify({
                        id: localStorage.getItem('user-id'),
                        projectid: prjId
                    })
                    let Strbody = ReadyAPIBody(str);
                    var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
                    var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

                    // let uploadUrlurl = uploadUrl + localStorage.getItem('user-id') + "/" + prjId
                    let uploadUrlurl = uploadUrl + encodeURIComponent(EncriptionBody) + "/"

                    // console.log("uploadurl=", uploadUrlurl)
                    await fetch(uploadUrlurl, {
                        headers: BasicAuth,
                        method: 'POST',
                        body: formData,
                    }).then(res => {
                        if (res.ok) {
                            // console.log(res);
                            // console.log("first process")
                        }
                    })
                        .catch(err => {
                            console.log("error in selectedFiles: ", err)
                            setDuplicateFileMsg("Server is unreachable, Please try after sometime")
                            setDuplicateFileMsgFlag(true)
                            setColoredRow(false)
                            // setFileSpinner(false)
                        })

                    if (e.target.files[i].type === "application/pdf") {
                        let url = createDocument

                        let str = JSON.stringify({
                            user_id: localStorage.getItem('user-id'),
                            // filename: e.target.files[i].name.replace("[", "").replace("]", "").replace("{", "").replace("}", ""),
                            filename: e.target.files[i].name,
                            project_id: prjId,
                            description: projectN,
                            is_visible: true,
                            status: "1 of 6: Document Uploaded",
                            guid: "",
                            // filepath: "/opt/data/" + localStorage.getItem('user-id') + '/' + prjId + '/' + e.target.files[i].name.replace("[", "").replace("]", "").replace("{", "").replace("}", ""),
                            filepath: "/opt/data/" + localStorage.getItem('user-id') + '/' + prjId + '/' + e.target.files[i].name,
                            createdate: moment(new Date()),
                            updatedate: moment(new Date())
                        })
                        let Strbody = ReadyAPIBody(str);
                        var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
                        var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

                        // console.log("Before createDocument API")
                        await fetch(url, {
                            method: 'POST',
                            headers: BasicAuth,
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            body: EncriptionBody
                        })
                            .then((response) => response.json())
                            .then((response) => {
                                if (response === 0) {
                                    // console.log("After createDocument API")
                                    displayFileDetailsForProject(prjId)
                                    // afterSuccessOfFT(e.target.files[i].name.replace("[", "").replace("]", "").replace("{", "").replace("}", ""))
                                    afterSuccessOfFT(e.target.files[i].name)
                                    // let auditfilename = e.target.files[i].name.replace("[", "").replace("]", "").replace("{", "").replace("}", "");
                                    let auditfilename = e.target.files[i].name;
                                    displayFileDetailsForProject(prjId).then(response => {
                                        response.map((data) => {
                                            if (data.filename === auditfilename) {
                                                let AuditDetailsIs1 = JSON.stringify({
                                                    user_id: localStorage.getItem('user-id'),
                                                    project_id: prjId,
                                                    document_id: data.id,
                                                    description: "File: " + auditfilename + " is uploaded",
                                                    updatedate: moment(new Date())
                                                });
                                                insertIntoAuditTbl(AuditDetailsIs1)
                                            }
                                        })
                                    })
                                }
                            })
                            .catch((error) => {
                                console.log("error in createDocument: ", error)
                                setDuplicateFileMsg("Server is unreachable, Please try after sometime")
                                setDuplicateFileMsgFlag(true)
                                setColoredRow(false)
                                // setFileSpinner(false)
                            });
                    }
                }
            }
            setIsDisabled(false)
            setUploading(false);
            setFileSpinner(false)
            if (msg.length !== 0) {
                setDuplicateFileMsg("Duplicate file: " + msg + " could not be uploaded")
                setDuplicateFileMsgFlag(true)
                displayFileDetailsForProject(prjId)
                window.scrollTo(0, 0)
            }
        }
        catch (e) {
            console.log("error in selectedFiles1: ", e)
        }
        setSpinnerVisibility(false)

    };

    const afterSuccessOfFT = async (e) => {
        const guidIsHere = await getGuid(e)                          // -------------   uncomment this line ------------- //
        try {
            // console.log("Before updateDocumentDetails API", guidIsHere)
            let str = JSON.stringify({
                guid: guidIsHere,
                proid: prjId,
                docname: e
            })
            let Strbody = ReadyAPIBody(str);
            var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
            var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

            // let url = updateDocumentDetails + prjId + '/' + encodeURIComponent(e)
            let url = updateDocumentDetails

            await fetch(url, {
                method: 'PUT',
                headers: BasicAuth,
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                body: EncriptionBody
            })
                .then((response) => response.json())
                .then((response) => {
                    // console.log("After updateDocumentDetails API", guidIsHere)
                })
                .catch((error) => {
                    console.log("error in afterSuccessOfFT: ", error)
                    setDuplicateFileMsg("Server is unreachable, Please try after sometime")
                    setDuplicateFileMsgFlag(true)
                    setColoredRow(false)
                    setFileSpinner(false)
                })
        }
        catch (e) {
            console.log("error in afterSuccessOfFT: ", e)
            if (e.response.status === 401) {
                // console.log("Before updateDocumentDetails API", guidIsHere)

                let str = JSON.stringify({
                    guid: guidIsHere,
                    proid: prjId,
                    docname: e
                })
                let Strbody = ReadyAPIBody(str);
                var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
                var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

                // let url = updateDocumentDetails + prjId + '/' + encodeURIComponent(e)
                let url = updateDocumentDetails

                await fetch(url, {
                    method: 'PUT',
                    headers: BasicAuth,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    body: EncriptionBody
                })
                    .then((response) => response.json())
                    .then((response) => {
                        console.log("After updateDocumentDetails API", guidIsHere)
                    })
                    .catch((error) => {
                        console.log("error in afterSuccessOfFT: ", error)
                        setDuplicateFileMsg("Server is unreachable, Please try after sometime")
                        setDuplicateFileMsgFlag(true)
                        setColoredRow(false)
                        setFileSpinner(false)
                    })
            }
        }
    }
    const submitExport = async () => {
        try {
            if (isExportClicked === true) {
                let urlExp = ""
                let urlFlag = false
                if (histincome && blnssheet && allstmntchecked) {
                    urlExp = "/api3/etl/transform?transform=All_STATEMENT&projectName=" + projectN + "&guids=" + expGuids + "&userId=" + localStorage.getItem('user-id') + "&projectId=" + prjId
                    urlFlag = true
                } else if (histincome && blnssheet) {
                    urlExp = "/api3/etl/transform?transform=All_STATEMENT&projectName=" + projectN + "&guids=" + expGuids + "&userId=" + localStorage.getItem('user-id') + "&projectId=" + prjId
                    urlFlag = true
                } else if (histincome === true) {
                    urlExp = "/api3/etl/transform?transform=HISTORICAL_INCOME&projectName=" + projectN + "&guids=" + expGuids + "&userId=" + localStorage.getItem('user-id') + "&projectId=" + prjId
                    urlFlag = true
                } else if (blnssheet === true) {
                    urlExp = "/api3/etl/transform?transform=BALANCE_SHEET&projectName=" + projectN + "&guids=" + expGuids + "&userId=" + localStorage.getItem('user-id') + "&projectId=" + prjId
                    urlFlag = true
                } else if (urlExp.length === 0) {
                    urlFlag = false
                }
                // console.log("urlExp== ", urlExp)
                if (urlFlag !== false) {

                    // try {
                    //     axios({
                    //         url: urlExp, //your url
                    //         method: 'GET',
                    //         responseType: 'blob', // important
                    //         auth: {
                    //             username: EnqueUserName,
                    //             password: EnqueUserPassword,
                    //         },
                    //         withCredentials: true
                    //     })
                    //         .then((response) => {
                    //             let fileType = (response.headers["content-disposition"].split("filename=")[1]).substring(1, response.headers["content-disposition"].split("filename=")[1].length - 1)

                    //             console.log("response== ", response);
                    //             console.log("response.data== ", response.data);
                    //             console.log("fileType==", fileType);

                    //             const href = URL.createObjectURL(response.data);

                    //             const link = document.createElement('a');
                    //             link.href = href;
                    //             link.setAttribute('download', fileType); //or any other extension
                    //             document.body.appendChild(link);
                    //             link.click();

                    //             document.body.removeChild(link);
                    //             URL.revokeObjectURL(url);

                    //             setExportSuccAlertFlag(true)
                    //             setAlertExpo(false)
                    //             setExportSuccAlertMsg("Exported Successfully!")
                    //             setIsClickedOnSubmit(false)
                    //         });

                    // }
                    // catch (error) {
                    //     console.log("error in submitexport: ", error)
                    //     setExportSuccAlertFlag(false)
                    //     if (error.response.status === 401) {
                    //         axios({
                    //             url: urlExp, //your url
                    //             method: 'GET',
                    //             responseType: 'blob', // important
                    //             auth: {
                    //                 username: EnqueUserName,
                    //                 password: EnqueUserPassword,
                    //             },
                    //             withCredentials: true
                    //         }).then((response) => {
                    //             let fileType = (response.headers["content-disposition"].split("filename=")[1]).substring(1, response.headers["content-disposition"].split("filename=")[1].length - 1)
                    //             const href = URL.createObjectURL(response.data);

                    //             const link = document.createElement('a');
                    //             link.href = href;
                    //             link.setAttribute('download', fileType); //or any other extension
                    //             document.body.appendChild(link);
                    //             link.click();

                    //             document.body.removeChild(link);
                    //             URL.revokeObjectURL(url);

                    //             setExportSuccAlertFlag(true)
                    //             setAlertExpo(false)
                    //             setExportSuccAlertMsg("Exported Successfully!")
                    //             setIsClickedOnSubmit(false)
                    //         });
                    //     }
                    // }

                    try {
                        var status_code;

                        fetch(urlExp, {
                            method: 'GET',
                            headers: BasicAuth2,
                            credentials: 'include',
                        })
                            .then(response => {
                                // console.log("export(blob) API resp= ", response, response.status)
                                status_code = response.status;
                                if (response.status == 200) {
                                    // console.log("im 401")
                                    return response.blob();
                                }
                            })
                            .then(blob => {
                                // console.log("blob", blob);
                                let fileType = "download.zip";
                                const href = URL.createObjectURL(blob);

                                const link = document.createElement('a');
                                link.href = href;
                                link.setAttribute('download', fileType); //or any other extension
                                document.body.appendChild(link);
                                link.click();

                                document.body.removeChild(link);
                                URL.revokeObjectURL(url);

                                setExportSuccAlertFlag(true)
                                setAlertExpo(false)
                                setExportSuccAlertMsg("Exported Successfully!")
                                setIsClickedOnSubmit(false)
                            })
                            .catch((error) => {
                                // console.log("status_code=", status_code);
                                console.log("Error while calling blob Api 1st time: ", error)
                                if (status_code == 401) {

                                    fetch(urlExp, {
                                        method: 'GET',
                                        headers: BasicAuth2,
                                        credentials: 'include',
                                    })
                                        .then(response => {
                                            if (response.status == 200) {
                                                // console.log("im 401")
                                                return response.blob();
                                            }
                                        })
                                        .then(blob => {
                                            // console.log("blob=", blob);
                                            let fileType = "download.zip";
                                            const href = URL.createObjectURL(blob);

                                            const link = document.createElement('a');
                                            link.href = href;
                                            link.setAttribute('download', fileType); //or any other extension
                                            document.body.appendChild(link);
                                            link.click();

                                            document.body.removeChild(link);
                                            URL.revokeObjectURL(url);

                                            setExportSuccAlertFlag(true)
                                            setAlertExpo(false)
                                            setExportSuccAlertMsg("Exported Successfully!")
                                            setIsClickedOnSubmit(false)
                                        })
                                        .catch((error) => {
                                            // console.log("status_code=", status_code);
                                            console.log("Error while calling blob Api 2nd time: ", error)
                                        })
                                }
                            })

                    } catch (error) {
                        console.log("Error while calling export(blob) api=", error)
                    }

                    let str = JSON.stringify({
                        projectid: prjId
                    })
                    let Strbody = ReadyAPIBody(str);
                    var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
                    var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

                    // let url = getDocumentDetails + localStorage.getItem('user-id') + '/' + prjId
                    let url = getDocumentDetails + encodeURIComponent(EncriptionBody) + "/"
                    return fetch(url, {
                        headers: BasicAuth,
                        'Content-Type': 'application/json',
                        'Accept': 'application/json',
                        method: 'GET',
                    })
                        .then((response) => response.json())
                        .then((response) => {
                            // console.log("After getDocumentDetails API")
                            response.map((data) => {
                                if (data.status === "6 of 6: Document Complete") {
                                    let AuditDetailsIs1 = JSON.stringify({
                                        user_id: localStorage.getItem('user-id'),
                                        project_id: prjId,
                                        document_id: data.id,
                                        description: "File " + data.filename + " is Exported",
                                        updatedate: moment(new Date())
                                    });
                                    insertIntoAuditTbl(AuditDetailsIs1)
                                }
                            })
                        })
                        .catch(e => {
                            console.log("error in export submit getDocumentDetails url: ", e)
                            setDuplicateFileMsg("Server is unreachable, Please try after sometime")
                            setDuplicateFileMsgFlag(true)
                            setColoredRow(false)
                            setFileSpinner(false)
                        })

                } else {
                    setAlertExpo(true)
                    setIsClickedOnSubmit(false)
                    setExportSuccAlertFlag(false)
                }
            }
        }
        catch (e) {
            setAlertExpo(true)
            setIsClickedOnSubmit(false)
            setExportSuccAlertFlag(false)
        }

    }


    const allstatementcheck = () => {
        var checkboxes = document.getElementsByName('buttonselect');
        for (var i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].type === 'checkbox') {
                checkboxes[i].checked = true;
                setIsExpClicked(!isExportAllClicked)
            }
        }
    }
    const Selectedbutton = (event, ischecked) => {
        if (event === "All_STATEMENT") {
            setallstmntchecked(ischecked)
            setblnssheet(true)
            sethistincome(true)
            setExportSuccAlertFlag(false)
            setAlertExpo(false)
        }
        else if (event === "BALANCE_SHEET") {
            setblnssheet(ischecked)
        }
        else if (event === "HISTORICAL_INCOME") {
            sethistincome(ischecked)
        }
    }

    const onClickOfExpButton = () => {
        try {
            setFileSpinner(true)
            let allguids = ""
            // console.log("Before getDocumentDetails API")

            let str = JSON.stringify({
                projectid: prjId
            })
            let Strbody = ReadyAPIBody(str);
            var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
            var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

            // let url = getDocumentDetails + localStorage.getItem('user-id') + '/' + prjId
            let url = getDocumentDetails + encodeURIComponent(EncriptionBody) + "/"
            fetch(url, {
                method: 'GET',
                headers: BasicAuth,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            })
                .then((response) => response.json())
                .then((response) => {
                    // console.log("After getDocumentDetails API")
                    response.map((data, index) => {
                        if (data.status === '6 of 6: Document Complete') {
                            if ((response.length - 1) === index) {
                                return allguids += data.guid
                            }
                            else {
                                return allguids += data.guid + ','
                            }
                        }
                    })
                    setExpGuids(allguids)
                    setTimeout(() => {
                        setFileSpinner(false)
                        setExportClicked(true)
                    }, 1000)
                })
                .catch((err) => {
                    console.log("err in onClickOfExpButton: ", err)
                    setDuplicateFileMsg("Server is unreachable, Please try after sometime")
                    setDuplicateFileMsgFlag(true)
                    setColoredRow(false)
                    setFileSpinner(false)
                })
        }
        catch (err) {
            console.log("err in onClickOfExpButton1: ", err)
        }
    }
    const checkanychangedone = () => {

        if (checkval == checkorg) {
            onFinish("noAction")
        } else {
            onFinish("action")
        }
    }
    return (
        <>
            <h1 id="hr1" style={{ padding: 0, margin: 0 }}></h1>
            <div style={{ margin: '35px', padding: '0', marginTop: '0px' }}>

                <Header />
                <div className="bg-light  container-fluid">
                    <div className="row">
                        <div className="col-md-2  max-auto" >
                            <div style={{ width: '100%', minHeight: '70vh' }}>
                                <div>
                                    <button
                                        variant=""
                                        disabled={Uploading}
                                        onClick={() => createProject()}
                                        type="button"
                                        className="btn  btn-lg"
                                        id="bttn"
                                        style={{ width: '100%', background: '#1B78DF', color: 'white' }}
                                    >
                                        <span style={{ float: 'left' }} id="createProjectTitle">Create Project</span>
                                        <a id='icon1' style={{ float: 'right' }}>
                                            <i className="fa fa-plus"></i>
                                        </a>
                                    </button>
                                    <hr style={{ border: '1px   #DBDDDE' }}></hr>
                                </div>
                                {
                                    isCreateClicked ?
                                        <div className="row">
                                            <Form onFinish={saveClicked}>
                                                <OutsideClickHandler onOutsideClick={() => {
                                                    setIsCreateClicked(false)
                                                    setProjectName("New Project")
                                                    setErrorOccured(false)
                                                    if (dataSource.length !== 0) {
                                                        setIsDisabled(false)
                                                    } else {
                                                        setIsDisabled(true)
                                                    }
                                                }}>
                                                    <Input maxLength={100} type="text" onKeyDown={(e) => { if (e.key === 'Escape') { myFunction("EscAtCreateProject") } }} onFocus={(e) => e.target.select()} defaultValue="New Project" name="New Project" onChange={(e) => checkProjectName(e.target.value)} autoFocus="New Project" />&nbsp;&nbsp;

                                                    <button type="submit" className='btn ' style={{ width: '45%', marginTop: '2%', background: '#F27A18', color: 'white', border: '1px' }}>Save</button>&nbsp;&nbsp;
                                                    <button className="btn" style={{ width: '45%', marginTop: '2%', background: 'black', color: 'white' }} onClick={() => {
                                                        setIsCreateClicked(false)
                                                        setErrorOccured(false)
                                                        setProjectName("New Project")
                                                        if (dataSource.length !== 0) {
                                                            setIsDisabled(false)
                                                        } else {
                                                            setIsDisabled(true)
                                                        }
                                                    }} >Cancel</button>

                                                </OutsideClickHandler>
                                            </Form>
                                        </div>
                                        : null
                                }

                                <div>
                                    <Form form={form} onFinish={() => checkanychangedone(checkExistProject, projectN)} >
                                        {
                                            errorOccured ?
                                                <div>
                                                    <div className="alert alert-danger" style={{ fontSize: '14px', fontWeight: '600', padding: 0, margin: 0, borderRadius: 0, textAlign: 'center', marginTop: '4px' }}>
                                                        {errorMsg}
                                                    </div>
                                                </div>
                                                : null
                                        }

                                        <Table

                                            columns={columns}
                                            dataSource={dataSource}
                                            pagination={true}
                                            size='small'
                                            rowKey="id"
                                            id='table1'
                                            style={{ pointerEvents: Uploading === true ? 'none' : null, opacity: Uploading ? 0.5 : 1.0, cursor: Uploading ? null : 'pointer' }}
                                            onRow={(record, rowIndex) => {
                                                return {
                                                    onClick: event => {
                                                        if (spinnerVisibility === false) {
                                                            setPrjId(record.id)
                                                            setProjectN(record.description)
                                                            setIsDisabled(false)
                                                            setExportSuccAlertFlag(false)
                                                            setExportClicked(false)
                                                            displayFileDetailsForProject(record.id)
                                                            localStorage.setItem('project-id-is', record.id);
                                                            localStorage.setItem('project-name-is', record.description);
                                                        }
                                                    },
                                                }
                                            }}
                                            rowClassName={(record, index) => record.description === projectN ? 'active-row' : 'table-row-dark'}
                                            loading={{
                                                indicator: <div className="spinner-border text-success
                                        " role="status">
                                                </div>,
                                                spinning: coloredRow
                                            }}
                                            showSorterTooltip={false}


                                        >
                                        </Table>

                                    </Form>

                                </div>

                                <div>
                                    <Modal closable={false} open={isModalOpen1} onOk={handleOk1} onCancel={handleCancel1}>
                                        <p id='projectpopup'>Are you sure you want to delete the project "{projectN}" and all its files ?</p>
                                    </Modal>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-10 col-12 max-auto fs-2" >
                            <div className=" col-12 fs-2 ">
                                <div className="col" style={{ height: '80px' }}>

                                    {/* <span id="projectid">{projectN}</span> */}

                                    <Tooltip placement={'bottom'} mouseEnterDelay={0} mouseLeaveDelay={0.1} trigger={'hover'}
                                        // onVisibleChange={}
                                        align={{ offset: [100, 10], }}
                                        overlay={<span style={{ height: 50, width: '120%' }}>{projectN}</span>}
                                    //   transitionName={false}
                                    >
                                        <label
                                            className="setwidthCreate concatCreate mdc-tooltip"
                                            id="tooltip-id"
                                            role="tooltip"
                                        //   style={{width:'100%',height:'35px',borderRadius:'5px', fontWeight:'bold',backgroundColor:'#eaeaea'}}
                                        >
                                            <div
                                                className="mdc-tooltip__surface mdc-tooltip__surface-animation"
                                                style={{ fontSize: "20px", fontWeight: "bold" }}
                                            // style={{padding:0,position: 'absolute',fontSize:'18px',marginTop:'0px' ,marginLeft:'10px',width:'45%'}}
                                            >
                                                {projectN}
                                            </div>
                                        </label>
                                    </Tooltip>

                                    <button style={{ cursor: exportbuttonon ? "not-allowed" : "pointer", background: exportbuttonon ? 'grey' : "black", border: exportbuttonon ? '1px solid grey' : "1px solid black", height: '46px', width: '167px' }} type="button" disabled={exportbuttonon} className=" btn-dark btn-lg" id="bttn2_Export" onClick={() => {
                                        onClickOfExpButton()
                                    }
                                    }><a style={{ cursor: exportbuttonon ? "not-allowed" : "pointer" }}><i className="fa fa-file-export" style={{ cursor: exportbuttonon ? "not-allowed" : "pointer", marginLeft: '8px', marginRight: '10px' }} id='icon2'></i></a><span style={{ marginRight: '10px' }}>Export</span>
                                    </button>

                                    <label disabled={isDisabled} className="btn-warning btn-lg" id="bttn2_Upload" style={{ background: isDisabled ? 'grey' : "#F68324", cursor: isDisabled ? "not-allowed" : "pointer", borderRadius: '5px', height: '46px', width: '167px', fontFamily: 'poppins' }} htmlFor="file"><a style={{ cursor: isDisabled ? "not-allowed" : "pointer", paddingLeft: '15px', backgroundColor: '' }}><i className="fa fa-arrow-up-from-bracket" id='icon2' style={{ marginRight: '17px' }}></i></a>Upload</label>

                                    <button type="button" className="refreshbtn" disabled={(fileDataSource.length === 0 || Uploading !== false) ? true : false} onClick={() => {
                                        displayFileDetailsForProject(prjId)
                                    }} style={{ cursor: fileDataSource.length ? 'pointer' : 'not-allowed', backgroundColor: fileDataSource.length ? "#1B78DF" : "gray", float: 'right', marginRight: '2%', border: '1px', borderRadius: '15%', height: '45px', fontFamily: 'poppins' }}><FontAwesomeIcon icon={faRefresh} style={{ height: '25px', marginBottom: '5px', color: 'white', width: '25px' }} /></button>
                                    <input accept=".pdf" style={{ marginLeft: '75%', width: "1px", height: '1px',visibility:'hidden' }} disabled={isDisabled} type="file" multiple id="file" onClick={(e) => e.target.value = ""} onChange={(e) => {
                                        selectedFiles(e)
                                    }} />


                                </div>

                                {/* <Card className="uploadProjectCard"> */}
                                {
                                    duplicateFileMsgFlag === true ?
                                        <div>
                                            <OutsideClickHandler
                                                onOutsideClick={() => {
                                                    setDuplicateFileMsgFlag(false)
                                                }}
                                            >
                                                <div className="alert alert-danger" style={{ width: '96%', marginLeft: '2%', marginTop: '5px', fontWeight: '5', fontSize: '20px' }}>
                                                    {duplicateFileMsg}
                                                </div>
                                            </OutsideClickHandler>
                                        </div>
                                        :
                                        null
                                }
                                {/* <Card className="tableCardstyle" style={{backgroundColor:'green'}}> */}
                                {
                                }

                                {
                                    isExportClicked ?
                                        <Card style={{ width: '50%', margin: "10%", marginLeft: '25%', border: '1px solid #ccc', padding: '0%', borderRadius: '20px' }} id="exportbuttonclicked">
                                            {
                                                alertExpo ? <div className="alert alert-danger" style={{ fontSize: '14px', fontWeight: '600' }}>{"Please select any one of the option"}</div> : null
                                            }
                                            {
                                                exportSuccAlertFlag ? <div className="success alert alert-success" style={{ fontSize: '14px', fontWeight: '600' }}>{exportSuccAlertMsg}</div> : null
                                            }
                                            <h4 style={{ textAlign: 'left', margin: 0, color: '#006699' }}>Export</h4>
                                            <hr />
                                            <div style={{ flexDirection: 'row' }}>
                                                <input type="checkbox" defaultChecked={true} name="buttonselect" onClick={() => Selectedbutton('HISTORICAL_INCOME', !histincome)} disabled={isExportAllClicked} style={{ marginRight: '3%' }}></input>
                                                <label style={{ fontSize: '16px', fontWeight: '600' }}>HISTORICAL_INCOME</label>
                                            </div>
                                            <div style={{ flexDirection: 'row' }}>
                                                <input type="checkbox" defaultChecked={true} name="buttonselect" onClick={() => Selectedbutton("BALANCE_SHEET", !blnssheet)} disabled={isExportAllClicked} style={{ marginRight: '3%' }}></input>
                                                <label style={{ fontSize: '16px', fontWeight: '600' }}>BALANCE_SHEET</label>
                                            </div>
                                            <div style={{ flexDirection: 'row' }}>
                                                <input type="checkbox" id="select-all" onChange={(e) => allstatementcheck(e)} onClick={() => Selectedbutton("All_STATEMENT", !allstmntchecked)} defaultChecked={true} style={{ marginRight: '3%' }}>
                                                </input>
                                                <label style={{ fontSize: '16px', fontWeight: '600' }} >All_STATEMENT</label>
                                            </div>

                                            <div style={{ flexDirection: 'row', paddingTop: '5%' }}>
                                                <button className="btn " style={{ width: '45%', marginRight: '10%', fontWeight: '600', backgroundColor: '#F27A18', color: 'white' }} onClick={() => {
                                                    setIsClickedOnSubmit(true)
                                                    setExportSuccAlertFlag(false)
                                                    submitExport()
                                                }}>
                                                    {
                                                        isClickedOnSubmit ?
                                                            <div className='divsection1' style={{ marginLeft: '45%' }}>
                                                                <ThreeDots
                                                                    height="25"
                                                                    width="25"
                                                                    color='white'
                                                                    ariaLabel='loading'

                                                                />
                                                            </div>
                                                            :
                                                            "Submit"
                                                    }
                                                </button>
                                                <button className="btn btn-dark " style={{ width: '45%', fontWeight: '600', backgroundColor: 'balck', color: 'white' }} onClick={() => {
                                                    setExpGuids('')
                                                    setExportClicked(false)
                                                    setAlertExpo(false)
                                                    setExportSuccAlertFlag(false)
                                                    sethistincome(true)
                                                    setblnssheet(true)
                                                    setallstmntchecked(true)
                                                    setIsExpClicked(true)
                                                    setIsClickedOnSubmit(false)
                                                }}>Cancel</button>
                                            </div>
                                        </Card>
                                        :
                                        <Table
                                            className="table-striped-rows"
                                            columns={columns_files}
                                            dataSource={fileDataSource}
                                            id="table2"
                                            pagination={fileDataSource.length > 10 ? true : false}
                                            size='small'
                                            rowKey="id"
                                            showSorterTooltip={false}
                                            style={{ minHeight: '60vh' }}

                                            loading={{
                                                indicator: <div className="spinner-border text-success" role="status">
                                                </div>,
                                                spinning: fileSpinner || spinnerVisibility,
                                            }}
                                        >

                                        </Table>
                                }
                                {/* </Card> */}
                                {/* </Card> */}

                                <div>
                                    <Modal

                                        closable={false}
                                        id="modalbox2"
                                        open={isModalOpen}
                                        onOk={handleOk}
                                        onCancel={handleCancel}
                                    >
                                        <p id='filepopup'>Are you sure you want to delete the file "{DeleteFileName}" ?</p>
                                    </Modal>
                                </div>
                            </div>
                        </div>
                    </div>

                    <hr></hr>
                </div>
            </div>
        </>

    )
}
export default CreateProjectScreen;