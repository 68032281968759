import React from "react";
import './Verification.css'
import "antd/dist/antd.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import moment from 'moment';
import { Card, Spinner, Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';
import { BasicAuth, ReCalDescripency, GetTaxDetails, GetTaxContentDetails, UpdateTaxDetails, DownloadPdf, EnqueUserName, EnqueUserPassword, getDocumentDetails, BasicAuth2, ReadyAPIBody } from '../../BusinessLogic/JavaApis/JavaApi';
import axios from 'axios';
import { Table, Form, Input } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OutsideClickHandler from 'react-outside-click-handler';
import { faXmark, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { useIdleTimer } from 'react-idle-timer';
import { timeToLogOut } from '../../BusinessLogic/setTimeOut';
import { pageNavigationPlugin } from '@react-pdf-viewer/page-navigation';
import { ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/toolbar';
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import '@react-pdf-viewer/page-navigation/lib/styles/index.css';
import { Navigate } from 'react-router-dom'
import { WarningOutlined } from '@ant-design/icons'
import Header from '../Header.js';

import ReactDOM from 'react-dom';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap.css';
import placements from 'rc-tooltip/lib/placements';
import CryptoJS from "crypto-js";

const Verification = () => {
    const location = useLocation()
    let navigate = useNavigate();
    const [form] = Form.useForm();
    const { ProjectId, FileName, ProjectNIs } = location.state
    const renderToolbar = (Toolbar = React.createElement) => (
        <Toolbar>
            {(ToolbarSlot) => {
                const {
                    CurrentPageInput,
                    Download,
                    EnterFullScreen,
                    GoToNextPage,
                    GoToPreviousPage,
                    NumberOfPages,
                    Open,
                    Print,
                    SwitchTheme,
                    Zoom,
                    ZoomIn,
                    ZoomOut,
                } = ToolbarSlot;

                return (
                    <div className="rpv-toolbar" role="toolbar" aria-orientation="horizontal" >
                        <div className="rpv-toolbar__left">
                            <div className="rpv-core__display--hidden rpv-core__display--block-small">
                                <div className="rpv-toolbar__item">
                                    &nbsp;<GoToPreviousPage />
                                    <CurrentPageInput />/ &nbsp;
                                    <NumberOfPages />&nbsp;
                                    < GoToNextPage />
                                </div>
                            </div>
                        </div>
                        <div className="rpv-toolbar__center customize">
                            <ZoomOut />
                            <Zoom />
                            <ZoomIn />
                        </div>
                    </div>
                );
            }}
        </Toolbar>
    );
    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        renderToolbar,
        sidebarTabs: () => [],
    })
    const pageNavigationPluginInstance = pageNavigationPlugin();
    const { jumpToPage } = pageNavigationPluginInstance;
    const [scheduleIndexData, setScheduleIndexData] = React.useState([])
    const [taxContentDetails, setTaxContentDetails] = React.useState([])
    const [checkIsEditedAnyColumn, setCheckIsEditedAnyColumn] = React.useState(false)
    const [selectedSchedule, setSelectedSchedule] = React.useState('')
    const [selectedPage, setSelectedPage] = React.useState('')
    const [editingLineColumn, setEditingLineColumnRow] = React.useState(null)
    const [editingValueColumn, setEditingValueColumnRow] = React.useState(null)
    const [taxDetailsContentLoader, setTaxDetailsContentLoader] = React.useState(false)
    const [successAlertFlag, setSuccessAlertFlag] = React.useState(false)
    const [wrongAlertFlag, setWrongAlertFlag] = React.useState(false)
    const [successMsg, setSuccessMsg] = React.useState('')
    const [wrongMsg, setWrongMsg] = React.useState('')
    const [noNextPrevPageErr, setNoNextPrevPageErr] = React.useState(false)
    const [refresh, setRefresh] = React.useState(false)
    const [cardClicked, setCardClicked] = React.useState(false)
    const [show, setshow] = React.useState(false);
    const [popconfimon, setpopconfimon] = React.useState(false);
    const [string, setstring] = React.useState("");
    const [selectedScheduleCopy, setSelectedScheduleCopy] = React.useState('')
    const [selectedPageCopy, setSelectedPageCopy] = React.useState('')
    const [time, setTime] = React.useState(timeToLogOut * 60 * 1000)
    const [orgLine, setorgLine] = React.useState("");
    const [orgvalue, setorgvalue] = React.useState("");
    const [waitForComplete, setWaitForComplete] = React.useState(false)
    const [Guid, setGuid] = React.useState("");
    const [PdfShowFlag, setPdfShowFlag] = React.useState(true);

    const taxDetailsTableHeaders = [
        {
            title: 'Line',
            dataIndex: 'line_original',
            key: 'line',
            ellipsis: true,
            width: '37%',
            render: (text, record, index) => {
                if (editingLineColumn === record.order_number) {
                    return <Form.Item
                        rules={[{
                            required: true,
                        }]}
                        style={{ padding: 0, margin: 0 }}
                    >
                        <OutsideClickHandler onOutsideClick={() => clickedOutside()}>
                            <Input maxLength={250} style={{ width: '90%' }} onFocus={(e) => e.target.select()} autoFocus={true} onKeyUp={(e) => { if (e.key === 'Enter') { setEditingLineColumnRow(null) } else if (e.key === 'Escape') { clickedCrossMark(index) } }} defaultValue={record.line_edited}
                                onChange={(e) => { checkIsEditedLine(e, index) }} ></Input>
                            <FontAwesomeIcon icon={faXmark} onClick={() => clickedCrossMark(index)} className='heading3' />
                        </OutsideClickHandler>
                    </Form.Item>
                }
                // else {
                //     return <div
                //         onDoubleClick={() => {
                //             setEditingLineColumnRow(record.order_number)
                //             setEditingValueColumnRow(null)
                //             setorgLine(record.line_edited)
                //         }} className='heading4'>
                //         <span className='heading5'>
                //             {
                //                 record.line_edited.length === 0 ? "" : record.line_edited
                //             }
                //         </span>
                //     </div>
                // }

                {
                    const content = record.line_edited.length === 0 ? "" : record.line_edited;
                    const isTextOverflow = content.length > 36; // Assuming 50 characters as the threshold for overflow



                    const staticElement = (
                        <div
                            onDoubleClick={() => {
                                setEditingLineColumnRow(record.order_number);
                                setEditingValueColumnRow(null);
                                setorgLine(record.line_edited);
                            }}
                            className='heading4'
                            style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        >
                            <span className='heading5'>
                                {content}
                            </span>
                        </div>
                    );

                    return isTextOverflow ? (
                        <Tooltip
                            placement={'bottom'}
                            mouseEnterDelay={0}
                            mouseLeaveDelay={0.00001}
                            trigger={'hover'}
                            title={content}
                            align={{ offset: [60, 20], }}
                            overlayInnerStyle={{ width: '500px' }}
                            overlay={<span style={{ height: 1, width: '100px', textOverflow: 'initial' }}>{content}</span>}
                        >
                            {staticElement}
                        </Tooltip>
                    ) : staticElement;
                }

            },
        },
        {
            title: 'Value',
            dataIndex: 'val_original',
            key: 'updatedate',
            ellipsis: true,
            width: '20%',
            render: (text, record, index) => {
                if (editingValueColumn === record.order_number) {
                    return <Form.Item
                        rules={[{
                            required: true,
                        }]}
                        style={{ padding: 0, margin: 0 }}
                    >
                        <OutsideClickHandler onOutsideClick={() => clickedOutside1()}>
                            <Input maxLength={50} style={{ width: '90%' }} onFocus={(e) => e.target.select()} autoFocus={true} onKeyUp={(e) => { if (e.key === 'Enter') { setEditingValueColumnRow(null) } else if (e.key === 'Escape') { clickedCrossMark1(index) } }} defaultValue={record.val_edited} onChange={(e) => {
                                checkIsEditedValue(e, index)
                            }} ></Input>
                            <FontAwesomeIcon icon={faXmark} onClick={() => clickedCrossMark1(index)} className='heading8' />
                        </OutsideClickHandler>
                    </Form.Item>
                }
                else {
                    return <div onDoubleClick={() => {
                        setEditingValueColumnRow(record.order_number)
                        setEditingLineColumnRow(null)
                        setorgvalue(record.val_edited)
                    }} className='heading9'>
                        <span className='heading10'>
                            {
                                // record.val_edited.length === 0 ? "" : parseInt(record.val_edited.toLocaleString().replace(/,/g, '')).toLocaleString()
                                record.val_edited.length === 0 ? "" :

                                    (record.line_edited !== "e If property is produced or acquired for resale, do the rules of section 263A apply to the entity? See instructions" ?
                                        (record.line_edited !== "(i) Cost" ?
                                            (record.line_edited !== "(ii) Lower of cost or market" ?
                                                (record.line_edited !== "(iii) Other (Specify method used and attach explanation)." ?
                                                    (record.line_edited !== "f Was there any change in determining quantities, cost, or valuations between opening and closing inventory? If Yes, attach explanation" ?
                                                        parseInt(record.val_edited.toLocaleString().replace(/,/g, '')).toLocaleString()
                                                        : record.val_edited)
                                                    : record.val_edited)
                                                : record.val_edited)
                                            : record.val_edited)
                                        : record.val_edited)
                            }
                        </span></div>
                }
            },
        }
    ]

    const handleOnIdle = event => {
        // console.log('last active', getLastActiveTime())
        localStorage.removeItem('my-key');
        localStorage.removeItem('user-id');
        localStorage.removeItem('temp_userName')
        localStorage.removeItem('userCred')
        localStorage.removeItem('project-id-is');
        localStorage.removeItem('project-name-is');
        navigate('/')
    }

    const handleOnActive = event => {
        // console.log('time remaining', getRemainingTime())
        setTime(time)
    }

    const handleOnAction = (e) => {
        setTime(time)
    }

    const { getRemainingTime, getLastActiveTime } = useIdleTimer({
        timeout: time,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        onAction: handleOnAction,
        debounce: 100
    })

    React.useEffect(() => {
        const callBackFun = async () => {
            try {
                let str = JSON.stringify({
                    projectid: ProjectId,
                    documentname: FileName
                })
                let Strbody = ReadyAPIBody(str);
                var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
                var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();


                // let url = GetTaxDetails + ProjectId + '/' + encodeURIComponent(FileName)
                let url = GetTaxDetails + encodeURIComponent(EncriptionBody) + "/"
                await fetch(url, {
                    headers: BasicAuth,
                })
                    .then((response) => response.json())
                    .then((response) => {
                        function testAsync() {
                            return new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    if (response.length !== 0) {
                                        setScheduleIndexData(response)
                                        if (Guid == '') {
                                            getTaxContentDetails1(response[0].schedule, response[0].page_number)
                                            setSelectedSchedule(response[0].schedule)
                                            setSelectedPage(response[0].page_number)
                                            setWaitForComplete(true)
                                        }
                                    } else {
                                        // console.log("response in verification useeffect in else: ", response)
                                    }

                                    resolve();
                                    ;
                                }, 1000
                                );
                            });
                        }
                        async function callerFun() {
                            await testAsync();

                            if (Guid == '') {
                                if (parseInt(response[0].page_number) > 0) {
                                    // console.log("jump to page")
                                    jumpToPage(response[0].page_number)
                                }

                                let str = JSON.stringify({
                                    projectid: ProjectId
                                })
                                let Strbody = ReadyAPIBody(str);
                                var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
                                var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

                                // let url = getDocumentDetails + localStorage.getItem('user-id') + '/' + ProjectId
                                let url = getDocumentDetails + encodeURIComponent(EncriptionBody) + "/"
                                return fetch(url, {
                                    headers: BasicAuth,
                                    method: 'GET',
                                    'Content-Type': 'application/json',
                                    'Accept': 'application/json'
                                })
                                    .then((response) => response.json())
                                    .then((response) => {
                                        response.map((data) => {
                                            if (data.filename === FileName) {
                                                setGuid(data.guid)
                                            }
                                        })
                                    })
                                    .catch(e => {
                                        console.log("error in while getting guid: ", e)
                                        setWaitForComplete(false)
                                    })
                            } else {
                                setWaitForComplete(false)
                            }

                        }
                        callerFun();
                    })
                    .catch((error) => {
                        console.log("error in useEffect Verification: ", error)
                        setTaxDetailsContentLoader(false)
                        setWrongAlertFlag(true)
                        setWrongMsg("Server is unreachable, Please try after sometimes")
                    })
            }
            catch (err) {
                console.log("error in verification1", err)
            }
        }
        callBackFun()
    }, [refresh]);

    const handleclose = () => {
        setSuccessAlertFlag(false)
        setshow(false);
        setpopconfimon(false);
        if (string === "back") {
            setTimeout(() => {
                navigate('/createproject')
            }, 1000)
        }
        else if (string === "schedule") {
            handler(selectedScheduleCopy, selectedPageCopy, "onClosemodel")
            onChangeSelectedSchedule(selectedScheduleCopy)
            onChangeSelectedPageIndex(selectedPageCopy)
            getTaxContentDetails1(selectedScheduleCopy, selectedPageCopy)
            setshow(false);
            setpopconfimon(false);
            setWrongAlertFlag(false);
        }
    }
    const handlesave = async () => {
        setWrongAlertFlag(false)
        setSuccessAlertFlag(false)
        await saveClickedInVerification();
        setshow(false);
        setpopconfimon(false);
        if (string === "back") {
            setTimeout(() => {
                navigate('/createproject')
            }, 1000)
        }
        else if (string === "schedule") {
            handler(selectedScheduleCopy, selectedPageCopy, "onClosemodel")
            onChangeSelectedSchedule(selectedScheduleCopy)
            onChangeSelectedPageIndex(selectedPageCopy)
            await getTaxContentDetails1(selectedScheduleCopy, selectedPageCopy)
            setshow(false);
            setpopconfimon(false);
        }

    }
    const Outsideclicked = () => {
        if (popconfimon === true) {
            setshow(true);
        }
    }

    const clickedCrossMark = (indexValue) => {
        setEditingLineColumnRow(null)
        if (popconfimon === true) {
            let taxDedailsReplica = [...taxContentDetails]
            taxDedailsReplica[indexValue].updatedate = new Date()
            taxDedailsReplica[indexValue].line_edited = orgLine
            setCheckIsEditedAnyColumn(true)
            setTaxContentDetails(taxDedailsReplica)
        }
    }

    const clickedCrossMark1 = (indexValue) => {
        setEditingValueColumnRow(null)
        if (popconfimon === true) {
            let taxDedailsReplica = [...taxContentDetails]
            taxDedailsReplica[indexValue].updatedate = new Date()
            taxDedailsReplica[indexValue].val_edited = orgvalue
            taxDedailsReplica["updatedate"] = moment(new Date())
            setCheckIsEditedAnyColumn(true)
            setTaxContentDetails(taxDedailsReplica)
        }
    }

    const checkIsEditedLine = (e, indexValue) => {
        setpopconfimon(true);
        setSuccessAlertFlag(false)
        setWrongAlertFlag(false)
        let taxDedailsReplica = [...taxContentDetails]
        taxDedailsReplica[indexValue].updatedate = new Date()
        taxDedailsReplica[indexValue].line_edited = e.target.value
        setCheckIsEditedAnyColumn(true)
        setTaxContentDetails(taxDedailsReplica)
    }

    const checkIsEditedValue = (e, indexValue) => {
        setpopconfimon(true);
        setSuccessAlertFlag(false)
        setWrongAlertFlag(false)
        let taxDedailsReplica = [...taxContentDetails]
        taxDedailsReplica[indexValue].updatedate = new Date()
        taxDedailsReplica[indexValue].val_edited = e.target.value
        taxDedailsReplica["updatedate"] = moment(new Date())
        setCheckIsEditedAnyColumn(true)
        setTaxContentDetails(taxDedailsReplica)
    }
    const clickedOutside = () => {
        setEditingLineColumnRow(null)
    }

    const clickedOutside1 = () => {
        setEditingValueColumnRow(null)
    }

    const getTaxContentDetails1 = async (schedule, index) => {
        try {
            if (schedule) {
                if (noNextPrevPageErr !== true) {
                    setTaxDetailsContentLoader(true)
                    let str = JSON.stringify({
                        projectid: ProjectId,
                        documentname: FileName,
                        schedule: schedule,
                        pagenumber: index
                    })
                    let Strbody = ReadyAPIBody(str);
                    var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
                    var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

                    // let urlGetContent = GetTaxContentDetails + ProjectId + '/' + encodeURIComponent(FileName) + '/' + schedule + '/' + index
                    let urlGetContent = GetTaxContentDetails + encodeURIComponent(EncriptionBody) + "/"
                    await fetch(urlGetContent, {
                        headers: BasicAuth,
                    })
                        .then((response) => response.json())
                        .then((response) => {
                            if (response.length !== 0) {
                                function waitToComplete() {
                                    return new Promise((resolve, reject) => {
                                        setTimeout(() => {
                                            setTimeout(() => {
                                                setTaxContentDetails(response)
                                            }, 100)
                                            resolve();
                                            ;
                                        }, 1000
                                        );
                                    });
                                }

                                async function callerFunction() {
                                    await waitToComplete();
                                    setWaitForComplete(false)
                                    setTaxDetailsContentLoader(false)
                                    setCardClicked(false)
                                    // console.log("End function", cardClicked)
                                }
                                callerFunction()
                            }
                        })
                        .catch((error) => {
                            console.log("error in getTaxContentDetails1 in verification: ", error)
                            setTaxDetailsContentLoader(false)
                            setWrongAlertFlag(true)
                            setWrongMsg("Server is unreachable, Please try after sometimes")
                        })
                }
            }
        }
        catch (error) {
            console.log("catch error in getTaxContentDetails1 = ", error)
        }
    }

    function onChangeSelectedSchedule(e) {
        // console.log("fifth function", cardClicked)
        setEditingLineColumnRow(null)
        setNoNextPrevPageErr(false)
        setWrongAlertFlag(false)
        setSuccessAlertFlag(false)
        setSelectedSchedule(e);
        let selectedPageIs = scheduleIndexData.filter(schedule => schedule.schedule === e).map((data, index) => {
            if (index === 0) {
                return data.page_number
            }
        })
        setSelectedPage(selectedPageIs[0])
        getTaxContentDetails1(e, selectedPageIs[0]);
    }
    function onChangeSelectedPageIndex(e, schedule) {
        // console.log("sixth function", cardClicked)
        setNoNextPrevPageErr(false)
        setEditingLineColumnRow(null)
        setWrongAlertFlag(false)
        setSuccessAlertFlag(false)
        setSelectedPage(e)
        getTaxContentDetails1(schedule, e)
    }

    const displayScheduleAndIndex = (scheduleIndexDataFilter) => {
        const uniqueScheduleNames = scheduleIndexDataFilter.map(e => e["schedule"]).map((e, i, final) => final.indexOf(e) === i && i).filter(e => scheduleIndexDataFilter[e]).map(e => scheduleIndexDataFilter[e]);
        uniqueScheduleNames.map((data, index) => {
            return (data.schedule)
        })
        return uniqueScheduleNames.map((data) => {
            return scheduleIndexDataFilter.map((data1, index) => {
                if (data1.schedule === data.schedule) {
                    return (
                        <div key={index} style={{ pointerEvents: waitForComplete === true ? 'none' : null, opacity: waitForComplete ? 0.7 : 1.0, cursor: waitForComplete === true ? 'not-allowed' : 'pointer' }}>
                            <Card className="" style={{ borderRadius: 0, backgroundColor: '#eaeaea', border: '#eaeaea' }} onClick={() => {
                                if ((data1.schedule === selectedSchedule && parseInt(data1.page_number) === parseInt(selectedPage)) !== true) {
                                    function SecondFunction() {
                                        setWaitForComplete(true)
                                        setCardClicked(true)
                                    }
                                    async function FirstFunction() {
                                        await SecondFunction()
                                        handler(data1.schedule, data1.page_number)
                                    }
                                    FirstFunction()
                                }
                            }}>
                                <p key={index} style={{ color: data1.schedule === selectedSchedule && parseInt(data1.page_number) === parseInt(selectedPage) ? '#0aa3f5' : 'black', marginTop: '10px', fontWeight: 'bold' }}> {data1.schedule}&nbsp;&nbsp;
                                    {
                                        data1.is_discrepancy === 'true' ? <FontAwesomeIcon icon={faCircleExclamation} style={{ width: '20px', height: '17px', color: '#F68324' }} /> : null
                                    }
                                </p>
                            </Card>
                            {/* <hr style={{ backgroundColor:'#eaeaea'}} /> */}
                        </div>
                    )
                }
            })
        })
    }

    const handler = async (schedule, pageNumber) => {
        // console.log("third function", cardClicked)
        setSelectedScheduleCopy(schedule)
        setSelectedPageCopy(pageNumber)
        const myFunc = async () => {
            // console.log("fourth function", cardClicked)
            setSelectedSchedule(schedule)
            setSelectedPage(pageNumber)
            onChangeSelectedSchedule(schedule)
            onChangeSelectedPageIndex(pageNumber, schedule)
            if (parseInt(pageNumber) === 0) {
                jumpToPage(0)
            } else {
                jumpToPage(pageNumber)
            }
            await getTaxContentDetails1(schedule, pageNumber)
        }
        if (popconfimon === true) {
            if (parseInt(pageNumber) === 0) {
                jumpToPage(0)
            } else {
                jumpToPage(pageNumber)
            }
            setshow(true);
            setstring("schedule")
            setSelectedScheduleCopy(schedule)
            setSelectedPageCopy(pageNumber)
        } else {
            myFunc()
        }
    };
    const handlePageChange = (e, check) => {
        // console.log("scroll function=", cardClicked)
        if (cardClicked === false) {
            // console.log("Enetred in scroll")
            if (scheduleIndexData.length !== 0) {
                if (check === "fromUseEffect") {
                    // console.log("inside function: ", check, e)
                    scheduleIndexData.forEach((data) => {
                        if (parseInt(data.page_number) === parseInt(e) + 1) {
                            handler(data.schedule, parseInt(e) + 1)
                        }
                    })
                }
                else if (check === "fromOnPageChange") {
                    let schedule = ""
                    scheduleIndexData.forEach((data) => {
                        if (parseInt(data.page_number) === parseInt(e.currentPage)) {
                            if (schedule === "") {
                                schedule = schedule + data.schedule
                            }
                        }
                    })
                    if (schedule.length !== 0) {
                        handler(schedule, parseInt(e.currentPage))
                    }
                }
            }
        }
        else {
            setCardClicked(false)
        }
    };
    const saveClickedInVerification = async () => {
        if (checkIsEditedAnyColumn === true) {
            setCheckIsEditedAnyColumn(false)
            setWaitForComplete(true)
            try {
                let str = JSON.stringify({
                    data: taxContentDetails,
                    proid: ProjectId,
                    docname: FileName,
                    scheduleid: selectedSchedule,
                    pagenr: selectedPage
                })

                let Strbody = ReadyAPIBody(str);
                var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
                var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();
                // console.log("put method Encription=", EncriptionBody)

                // let url = UpdateTaxDetails + ProjectId + '/' + encodeURIComponent(FileName) + '/' + selectedSchedule + '/' + selectedPage
                let url = UpdateTaxDetails
                setTaxDetailsContentLoader(true)
                await fetch(url, {
                    method: 'PUT',
                    headers: BasicAuth,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    body: EncriptionBody
                })
                    .then((response) => response.json())
                    .then((response) => {
                        setWrongAlertFlag(false)
                        setSuccessAlertFlag(true)
                        setSuccessMsg("Saved Successfully!")
                        // setTaxDetailsContentLoader(false)
                        setpopconfimon(false);


                        // let ReCalDescripencyurl = ReCalDescripency + Guid
                        // const data = axios.get(ReCalDescripencyurl, {
                        //     auth: {
                        //         username: EnqueUserName,
                        //         password: EnqueUserPassword,
                        //     },
                        //     withCredentials: true
                        // })
                        //     .then((response) => {
                        //         setRefresh(!refresh)
                        //         if (show === false) {
                        //             getTaxContentDetails1(selectedSchedule, selectedPage)
                        //         }
                        //         // console.log('ReCalDescripencyurl=', ReCalDescripencyurl)
                        //     })
                        //     .catch((error) => {
                        //         console.log("Error while calling ReCalDescripency Api: ", error)
                        //         setWaitForComplete(false)
                        //         setTaxDetailsContentLoader(false)
                        //     })

                        let API_URL = ReCalDescripency + Guid
                        fetch(API_URL, { headers: BasicAuth2, })
                            .then((response => response.text()))
                            .then((response) => {
                                // console.log("Descripency API RESPO=", response);
                                setRefresh(!refresh)
                                if (show === false) {
                                    getTaxContentDetails1(selectedSchedule, selectedPage)
                                }
                            })
                            .catch((error) => {
                                console.log("Error while calling ReCalDescripency Api: ", error)
                                setWaitForComplete(false)
                                setTaxDetailsContentLoader(false)
                            })


                    })
                    .catch((error) => {
                        console.log("Error in saveclickedverification function: ", error)
                        setTaxDetailsContentLoader(false)
                        setWrongAlertFlag(true)
                        setWrongMsg("Server is unreachable, Please try after sometimes")
                        setWaitForComplete(false)
                    })
            }
            catch (err) {
                setTaxDetailsContentLoader(false)
                setWaitForComplete(false)
                console.log("catch error at save InVerification", err)
            }
        }
        else {
            setWrongAlertFlag(true)
            setSuccessAlertFlag(false)
            setWrongMsg("No changes detected")
        }
    }

    const clickedBack = () => {
        if (popconfimon === true) {
            setshow(true);
            setstring("back")
        }
        else {
            navigate('/createproject')
        }
    }

    const onFinish = () => {
        // alert("wait")
    }

    let PDFstr = JSON.stringify({
        userid: localStorage.getItem('user-id'),
        projid: ProjectId,
        filename: FileName
    })
    let PDFStrbody = ReadyAPIBody(PDFstr);
    var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
    var PDFEncriptionBody = CryptoJS.AES.encrypt(PDFStrbody, EncPassword).toString();

    const [PdfShowFlag500, setPdfShowFlag500] = React.useState(PDFEncriptionBody);

    const showPdf = () => {
        var credentials = window.btoa(process.env.REACT_APP_AuthenticationName + ":" + process.env.REACT_APP_AuthenticationPassword);
        // const EncriptionBody = "U2FsdGVkX18MBSOoSCLpje5wyM29qBhdHMYL79OubfHcTnrJcBPfgNPiOYcBdMu0O2if7p2oaXr1+frvINddDMGZ3iq9XONZBQCYDi9ajsp0n+igttlzp1yCbqpaSQjRip4ntknoZvo1AXT0xw7tlrRHNMfsKSc1TOebozDU+N0="

        return (
            <>
                {/* <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.14.305/build/pdf.worker.min.js"> */}
                <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
                    <div style={{ height: "79vh" }}>
                        <Viewer style={{ border: '20px' }} initialPage={selectedPage} onPageChange={(e) => handlePageChange(e, "fromOnPageChange")}
                            // fileUrl={DownloadPdf + localStorage.getItem('user-id') + '/' + ProjectId + '/' + encodeURIComponent(FileName)}
                            fileUrl={DownloadPdf + encodeURIComponent(PdfShowFlag500) + "/"}
                            httpHeaders={{
                                "Authorization": `Basic ${credentials}`
                            }}
                            withCredentials={true}
                            plugins={[defaultLayoutPluginInstance, pageNavigationPluginInstance]} />
                    </div>
                </Worker>
            </>
        )
    }

    return (
        <>
            <h1 id="hr1" style={{ padding: 0, margin: 0 }}></h1>
            <div style={{ margin: '35px', padding: '0', marginTop: '0px' }}>

                <Header />
                <div className="row " style={{ margin: 0, marginLeft: '0px' }}>
                    <div className='col-lg-2 col-xl-2 auto verticleLine mt-1' style={{ height: "84vh", padding: 0, backgroundColor: '#eaeaea', borderRadius: '5px', overflowX: 'hidden', overflowY: 'auto' }}>
                        {/* <label style={{ fontWeight: '600' ,backgroundColor: 'white', fontSize: '15px', width: '100%', height: '30px', textAlign: 'center',border: '1px solid gray' }}>Bookmarks</label> */}
                        <label style={{ marginLeft: '7%', marginRight: '7%', marginTop: '2%', fontSize: '22px', textAlign: 'left', width: '90%', height: '45px', border: '1px solid gray', borderRadius: '5px' }} type="" className="btn btn-primary"><span style={{ marginLeft: '5%' }} id="bookmarking"> Bookmarks</span>
                            <i style={{ float: 'right', marginTop: '4px' }} className="fa-solid fa-bookmark"></i>
                        </label><br /><br />
                        <div style={{ marginLeft: '14%' }}>
                            {displayScheduleAndIndex(scheduleIndexData)}</div>
                    </div>

                    <div className='original_pdf_block col-lg-6 col-xl-6 mt-1' >
                        <label className="" style={{ width: '100%', height: '35px', borderRadius: '5px', fontWeight: 'bold', backgroundColor: '#eaeaea' }}>

                            <Tooltip placement={'bottom'} mouseEnterDelay={0} mouseLeaveDelay={0.1} trigger={'hover'}
                                // onVisibleChange={}
                                align={{ offset: [350, 10], }}
                                overlay={<span style={{ height: 50, width: '120%' }}>{ProjectNIs}/{FileName}</span>}
                            //   transitionName={false}
                            >
                                <label
                                    className="setwidth concat mdc-tooltip"
                                    id="tooltip-id"
                                    role="tooltip"
                                    style={{ width: '100%', height: '35px', borderRadius: '5px', fontWeight: 'bold', backgroundColor: '#eaeaea' }}
                                >
                                    <div
                                        className="mdc-tooltip__surface mdc-tooltip__surface-animation" id='ScrollLeft'
                                        style={{ padding: 0, position: 'absolute', fontSize: '18px', marginTop: '0px', marginLeft: '10px', width: '45%' }}
                                    >
                                        {ProjectNIs}/{FileName}
                                    </div>
                                </label>
                            </Tooltip>

                        </label>

                        <div className="Original_ver" style={{ marginTop: '4px' }}>
                            {showPdf()}
                        </div>
                    </div>

                    <div className='extracted_data_block col-lg-4 col-xl-4 heading19 mt-1' style={{ padding: 0, margin: 0, height: '87vh' }}>
                        {
                            wrongAlertFlag ?
                                <div className="alert alert-danger heading20">{wrongMsg}</div> :
                                null
                        }
                        {
                            successAlertFlag ?
                                <div className="alert alert-success heading21">
                                    {successMsg}
                                </div> :
                                null
                        }
                        {/* <span className="" style={{fontWeight:'bold',backgroundColor:'#eaeaea'}}>{selectedSchedule}</span> */}
                        <label className='label' style={{ height: '35px', color: '#0aa3f5', backgroundColor: '#eaeaea', width: '100%', fontWeight: 'bold', borderRadius: '5px', marginBottom: '5px' }}><span style={{ padding: "0", position: "absolute", marginTop: "3px", fontSize: '20px', marginLeft: '25px' }} id="setected_schedul">{selectedSchedule}</span></label>                    <Card>
                            <Form form={form} onFinish={() => onFinish("action")} style={{ marginTop: '4px' }}>
                                <div style={{ padding: 0, height: '74vh', marginTop: '4px' }}>
                                    <Table
                                        rowKey="order_number"
                                        className="table-striped-rows verification_table"
                                        columns={taxDetailsTableHeaders}
                                        dataSource={taxContentDetails}
                                        id="varification_Table"
                                        pagination={false}
                                        bordered
                                        size='small'
                                        scroll={taxContentDetails.length > 10 ? { y: "69vh" } : null}
                                        loading={{
                                            indicator: <div><Spinner animation="border" variant="success" size="default" /></div>,
                                            spinning: taxDetailsContentLoader
                                        }}
                                    >
                                    </Table>
                                </div>
                            </Form>
                        </Card>

                        <div className="heading22 ">
                            <button className="saveButton1 btn" style={{ margin: 0, backgroundColor: 'rgb(246,131,36)', color: 'white', width: '50%' }} onClick={() => saveClickedInVerification()} ><a style={{ marginRight: '10%' }}><i style={{ color: 'white', marginLeft: '5%' }} className="fa fa-download" aria-hidden="true"></i></a>Save</button>&nbsp;
                            <button className="backButton1 btn" style={{ margin: 0, backgroundColor: 'black', color: 'white', width: '50%' }} onKeyUp={(e) => { if (e.key === 'Enter') { clickedBack() } }} onClick={() => clickedBack()}><i className="fa fa-arrow-left" aria-hidden="true" style={{ marginRight: '10%' }}></i>Back</button>
                            <div className="heading23"></div>
                            <div>
                                <Modal show={show} className="centered" backdrop="static" onHide={() => Outsideclicked()}>
                                    <Modal.Body >
                                        <p style={{ fontSize: '20px' }}> Do you want to save the changes ? </p>
                                        <p></p>
                                        <p style={{ textAlign: 'center' }}>
                                            <label className="btn" onClick={() => handlesave()} style={{ color: 'white', backgroundColor: 'rgb(246,131,36)', width: '25%' }}>Save</label>&nbsp;&nbsp;&nbsp;&nbsp;
                                            <label className="btn" style={{ color: 'white', backgroundColor: 'black', width: '25%' }} onClick={() => handleclose()}>Cancel</label>
                                        </p>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Verification;
