import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import CreateProject from './Screens/CreateProject/CreateProject';
import './Screens/CreateProject/CreateProject.css';
import Forgotpassword from './Screens/Forgotpassword/Forgotpassword'
import RequestDemo from './Screens/RequestDemo/RequestDemo';
import Verification from './Screens/Verification/Verification';
import RequestAccess from './Screens/RequestAccess/RequestAccess';
import UpdateProfile from './Screens/Profile/UpdateProfile';
import ApprovalScreen from './Screens/Approval/Approval';
import PrivateRoute from './PrivateRoute';
import LoginFirstTimeScreen from './Screens/FirstTimeLogin/FirstTimeLogin';
import LicenseTerms from './Screens/Licenseterms/Licenseterms';
import NambriHelpContent from './Screens/NambriHelpContent/NambriHelpContent'

function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>

              <Route exact path="/" element={<RequestDemo/>}></Route>

              <Route path='/' element={<PrivateRoute/>}>
              <Route exact path='/firstlogin' element={<LoginFirstTimeScreen/>}></Route>
              <Route exact path="/createproject" element={<CreateProject />}></Route>
              <Route exact path='/verification' element={<Verification />}></Route>
              <Route exact path='/updateprofile' element={<UpdateProfile/>}></Route>
              <Route exact path='/licenseterms' element={<LicenseTerms/>}></Route>

              </Route>
              <Route exact path='/NambriHelpContent' element={<NambriHelpContent/>} />
              <Route path="/approval/:userName/:userLastName/:userEmail" element={<ApprovalScreen/>}/>
              <Route exact path="/forgot" element={<Forgotpassword />}></Route>
              <Route exact path='/reqaccess' element={<RequestAccess/>}></Route>
         </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
