import React from "react";
import IdleTimer from "react-idle-timer";
import { timeToLogOut } from '../../BusinessLogic/setTimeOut';

import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import { ReadyAPIBody, BasicAuth, specialCharsError, specialCharsNoError, AlphaSpecialCharsError, getUserDetails, updateUserDetails, updateNewPasswordInLdap } from '../../BusinessLogic/JavaApis/JavaApi';
import { states } from '../../BusinessLogic/US_States/US_States';
// import validator from 'validator'
import moment from 'moment';
import { Popconfirm } from 'antd';
import './Profile.css';

import Header from '../Header.js';

import CryptoJS from 'crypto-js';

class UpdateProfile1 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            clickedChangePass: false,
            selectedState: '',
            First_Name: '', Last_Name: '', Company_Name: '',
            Phone_Number: '', Email_Address: '', Role: '',
            Industry: '', Address: '', State: '', Zip: '',
            New_Pass: localStorage.getItem('userCred'), Confirm_Pass: localStorage.getItem('userCred'), City: '',
            alertSet: false,
            messageAlert: '',
            changesDone: false,
            errFirst_Name: '', errLast_Name: '', errCompany_Name: '',
            errPhone_Number: '', errEmail_Address: '', errRole: '',
            errIndustry: '', errAddress: '', errState: '', errZip: '',
            errNew_Pass: '', errConfirm_Pass: '', errCity: '',
            checkAllFieldsOk: true,
            setWrongAlert: false,
            wrongMsg: '',
            setTimeToLogOut: timeToLogOut * 1000 * 60
        }
        this.idleTimer = null
        this.handleOnAction = this.handleOnAction.bind(this)
        this.handleOnActive = this.handleOnActive.bind(this)
        this.handleOnIdle = this.handleOnIdle.bind(this)
    }

    confirmContinue = (e, backButton) => {
        this.clickedNext(e, backButton)
    };

    insertUserFirstLoginDetalsIntoDb = async () => {
        let str = JSON.stringify({
            fname: this.state.First_Name, lname: this.state.Last_Name,
            email: this.state.Email_Address, phone: this.state.Phone_Number,
            address: this.state.Address, state: this.state.selectedState,
            zip: this.state.Zip, role: this.state.Role, company: this.state.Company_Name,
            industry: this.state.Industry, city: this.state.City, updatedate: moment(new Date()),
            userid: localStorage.getItem('user-id'),
        })
        let Strbody = ReadyAPIBody(str);
        var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
        var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

        let url = updateUserDetails
        fetch(url, {
            method: 'PUT',
            headers: BasicAuth,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            body: EncriptionBody
        })
            .then((response) => response.json())
            .then((response) => {
                this.setState({ setWrongAlert: false })
                this.setState({ messageAlert: "Profile successfully updated", alertSet: true, changesDone: false })
                window.scrollTo(0, 0)
            })
            .catch((e) => {
                this.setState({ setWrongAlert: true, wrongMsg: "Server is unreachable, Please try after sometime" })
            })
    }

    insertNewPasswordIntoLdap = async () => {
        let str = JSON.stringify({
            username: this.state.Email_Address,
            password: this.state.New_Pass
        })

        let Strbody = ReadyAPIBody(str);
        var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
        var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

        await fetch(updateNewPasswordInLdap, { 
            method: 'PUT',
            headers: BasicAuth,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            body: EncriptionBody
        })
            .then((response) => response.json())
            .then((response) => {
                if (response === true) {
                    this.insertUserFirstLoginDetalsIntoDb()
                }
                else {
                    this.setState({ setWrongAlert: true, wrongMsg: "Something went wrong, Please try after sometime ldap" })
                    window.scrollTo(0, 0)
                }
            })
            .catch((e) => {
                this.setState({ setWrongAlert: true, wrongMsg: "Server is unreachable, Please try after sometime" })
            })
    }

    clickedNext = async (e, backButton) => {
        e.preventDefault()
        if (this.state.changesDone === true) {

            if (this.state.checkAllFieldsOk === true && this.state.errFirst_Name === '' && this.state.errLast_Name === '' &&
                this.state.errCompany_Name === '' && this.state.errPhone_Number === '' && this.state.errEmail_Address === '' && this.state.errRole === '' &&
                this.state.errIndustry === '' && this.state.errAddress === '' && this.state.errZip === '' &&
                this.state.errNew_Pass === '' && this.state.errConfirm_Pass === '' && this.state.errCity === '') {
                if (this.state.Address === '' || this.state.City === '' || this.state.Zip === '') {
                    this.setState({ setWrongAlert: true, wrongMsg: "Please fill all the mandatory fields" })
                    window.scrollTo(0, 0)
                }
                else if (this.state.New_Pass !== this.state.Confirm_Pass) {
                    this.setState({ setWrongAlert: true, wrongMsg: "New password and Confirm password is not matching" })
                    window.scrollTo(0, 0)
                }
                else if (this.state.New_Pass.length === 0 && this.state.Confirm_Pass.length === 0) {
                    this.setState({ errNew_Pass: 'New password is require!', checkAllFieldsOk: false })
                    this.setState({ errConfirm_Pass: 'Confirm password is require!', checkAllFieldsOk: false })
                } else if (this.state.New_Pass === this.state.Confirm_Pass) {
                    this.setState({ setWrongAlert: false })
                    await this.insertNewPasswordIntoLdap()
                    if (backButton === 'back') {
                        // console.log("setWrongAlert = ", this.state.setWrongAlert)
                        if (this.state.setWrongAlert === false) {
                            // console.log(" not here ");
                            setTimeout(() => {
                                this.cancelBack()
                            }, 1000);
                        }
                    }
                }
            }
            else {
                this.setState({ alertSet: false })
                this.setState({ setWrongAlert: true, wrongMsg: "Please correct the wrong fields" })
                window.scrollTo(0, 0)
            }
        }
        else {
            this.setState({ messageAlert: "No changes done in user profile", alertSet: true })
            window.scrollTo(0, 0)
        }
    }

    changeDetails = (e, field) => {
        const specialChars = /[=;*]/;
        if (field === 'fname') {
            e = e.trim();
            this.setState({ First_Name: e })
            if (e.length === 0) {
                this.setState({ errFirst_Name: 'First Name is required!', checkAllFieldsOk: false })
            } else if (!e.match(/^[a-zA-Z`!@#$%^&()_+\-[\]{}':"\\|,.<>/?~\s]*$/)) {
                this.setState({ errFirst_Name: specialCharsNoError, checkAllFieldsOk: false })
            } else {
                this.setState({ errFirst_Name: '', checkAllFieldsOk: true })
            }
        } else if (field === 'lname') {
            e = e.trim();
            this.setState({ Last_Name: e })
            if (e.length === 0) {
                this.setState({ errLast_Name: 'Last Name is required!', checkAllFieldsOk: false })
            } else if (!e.match(/^[a-zA-Z`!@#$%^&()_+\-[\]{}':"\\|,.<>/?~\s]*$/)) {
                this.setState({ errLast_Name: specialCharsNoError, checkAllFieldsOk: false })
            } else {
                this.setState({ errLast_Name: '', checkAllFieldsOk: true })
            }
        } else if (field === 'cname') {
            e = e.trim();
            this.setState({ Company_Name: e })
            if (e.length === 0) {
                this.setState({ errCompany_Name: 'Company Name is required!', checkAllFieldsOk: false })
            } else if (specialChars.test(e)) {
                this.setState({ errCompany_Name: specialCharsError, checkAllFieldsOk: false })
            } else {
                this.setState({ errCompany_Name: '', checkAllFieldsOk: true })
            }
        } else if (field === 'pNum') {
            e = e.trim();
            this.setState({ Phone_Number: e })
            if (e.length === 0) {
                this.setState({ errPhone_Number: '', checkAllFieldsOk: true })
            } else if (!e.match(/^[ 0-9`!@#$%^&()_+\-[\]{}':"\\|,.<>/?~]*$/)) {
                this.setState({ errPhone_Number: AlphaSpecialCharsError, checkAllFieldsOk: false })
            } else {
                this.setState({ errPhone_Number: '', checkAllFieldsOk: true })
            }
        } else if (field === 'email') {
            this.setState({ Email_Address: e })
        } else if (field === 'nPass') {
            this.setState({ New_Pass: e })
            if (e.length === 0) {
                this.setState({ errNew_Pass: 'New password is require!', checkAllFieldsOk: false })
            }
            else if (e.length < 8) {
                this.setState({ errNew_Pass: 'Password length must be greater than or equal to 8 characters!', checkAllFieldsOk: false })
            }
            else {
                this.setState({ errNew_Pass: '', checkAllFieldsOk: true })
            }
        } else if (field === 'cPass') {
            this.setState({ Confirm_Pass: e })
            if (e.length === 0) {
                this.setState({ errConfirm_Pass: 'Confirm password is require!', checkAllFieldsOk: false })
            }
            else if (e.length < 8) {
                this.setState({ errConfirm_Pass: 'Password length must be greater than or equal to 8 characters!', checkAllFieldsOk: false })
            }
            else {
                this.setState({ errConfirm_Pass: '', checkAllFieldsOk: true })
            }
        } else if (field === 'role') {
            e = e.trim();
            this.setState({ Role: e })
            if (e.length === 0) {
                this.setState({ errRole: "Role is required!", checkAllFieldsOk: false })
            } else if (specialChars.test(e)) {
                this.setState({ errRole: specialCharsError, checkAllFieldsOk: false })
            } else {
                this.setState({ errRole: "", checkAllFieldsOk: true })
            }
        } else if (field === 'industry') {
            e = e.trim();
            this.setState({ Industry: e })
            if (specialChars.test(e)) {
                this.setState({ errIndustry: specialCharsError, checkAllFieldsOk: false })
            } else {
                this.setState({ errIndustry: "", checkAllFieldsOk: true })
            }
        } else if (field === 'address') {
            this.setState({ Address: e })
            if (e.length === 0) {
                this.setState({ errAddress: "Address is required!", checkAllFieldsOk: false })
            } else if (specialChars.test(e)) {
                this.setState({ errAddress: specialCharsError, checkAllFieldsOk: false })
            } else {
                this.setState({ errAddress: "", checkAllFieldsOk: true })
            }
        } else if (field === 'zip') {
            e = e.trim();
            this.setState({ Zip: e })
            if (e.length === 0) {
                this.setState({ errZip: "Zip is required!", checkAllFieldsOk: false })
            } else if (specialChars.test(e)) {
                this.setState({ errZip: specialCharsError, checkAllFieldsOk: false })
            } else {
                this.setState({ errZip: "", checkAllFieldsOk: true })
            }
        } else if (field === 'city') {
            e = e.trim();
            this.setState({ City: e })
            if (e.length === 0) {
                this.setState({ errCity: "City is required!", checkAllFieldsOk: false })
            } else if (specialChars.test(e)) {
                this.setState({ errCity: specialCharsError, checkAllFieldsOk: false })
            } else {
                this.setState({ errCity: "", checkAllFieldsOk: true })
            }
        } else if (field === 'State') {
            this.setState({ State: e })
        }
        this.setState({ changesDone: true })
    }

    clickedBackButton = () => {
        this.props.navigate('/createproject')
    }

    componentDidMount() {
        this.refreshData();
    }

    refreshData = (fromCancel) => {
        // console.log("EncriptionData=", EncriptionBody)
        // const textFromStorage = localStorage.getItem('my-key');
        // const url = getUserDetails + textFromStorage
        let str = JSON.stringify({
            username: localStorage.getItem('my-key')
        })
        let Strbody = ReadyAPIBody(str);
        var EncPassword = process.env.REACT_APP_ENCRYPTION_KEY;
        var EncriptionBody = CryptoJS.AES.encrypt(Strbody, EncPassword).toString();

        const url = getUserDetails + encodeURIComponent(EncriptionBody)

        fetch(url, {
            headers: BasicAuth,
        })
            .then((response) => response.json())
            .then((response) => {

                this.setState({
                    First_Name: response[0].fname, Last_Name: response[0].lname,
                    Company_Name: response[0].company, Phone_Number: response[0].phone,
                    Email_Address: response[0].email, Role: response[0].role, Industry: response[0].industry,
                    Address: response[0].address, Zip: response[0].zip, City: response[0].city, selectedState: response[0].state,
                })
            })
            .catch((e) => {
                console.log("error")
                this.setState({ setWrongAlert: true, wrongMsg: "Server is unreachable, Please try after sometime" })
            })
    }

    cancelBack = (e) => {
        this.props.navigate('/createproject')
    };

    displayStates = (state) => {
        return (
            state.map((data, index) => {
                return <option key={index}>{data}</option>
            })
        )
    }
    onChangeSelectedState = (e) => {
        alert(e.target.value)
    }

    handleOnAction(event) {
        this.setState({ setTimeToLogOut: this.state.setTimeToLogOut })
    }

    handleOnActive(event) {
        this.setState({ setTimeToLogOut: this.state.setTimeToLogOut })
    }

    handleOnIdle(event) {
        localStorage.removeItem('my-key');
        localStorage.removeItem('user-id');
        localStorage.removeItem('temp_userName')
        localStorage.removeItem('userCred')
        localStorage.removeItem('project-id-is');
        localStorage.removeItem('project-name-is');
        this.props.navigate('/')
    }
    goBackToProjectScreen = (e) => {
        this.confirmContinue(e, "back")
    }

    render() {
        return (
            <>
                <h1 id="hr2" style={{ padding: 0, margin: 0 }}></h1>
                <div style={{ margin: '35px', padding: '0', marginTop: '0px' }}>
                    <Header />
                    <div className="bg-light container-fluid" style={{ paddingLeft: "0%", paddingRight: '3%', marginRight: "5%" }}>
                        {/* in the above line paddingLeft was 3% and I have changed it to 0% */}
                        <IdleTimer
                            ref={ref => { this.idleTimer = ref }}
                            timeout={this.state.setTimeToLogOut}
                            onActive={this.handleOnActive}
                            onIdle={this.handleOnIdle}
                            onAction={this.handleOnAction}
                            debounce={100}
                        />


                        <div>
                            {/* Added span tag beow with css  -----> 13-10-2022 */}
                            <span className="fs-2" id="user" style={{ marginLeft: '6%' }}>User Profile</span>
                            <form onSubmit={this.clickedNext} id="userform">
                                {/* in below line added class name  'spacing' , added styles for alert success and danger messeges-----> 13-10-2022 */}
                                <div className="row spacing" style={{ dispaly: 'flex', margin: '20px', width: '100%' }}>
                                    {
                                        this.state.alertSet ? <div className="alert alert-success" id="alertInProfile" style={{ width: '90%' }}>{this.state.messageAlert}</div> : null
                                    }
                                    {
                                        this.state.setWrongAlert ? <div className="alert alert-danger" id="updaedSuccessMsg" style={{ width: '90%' }}>{this.state.wrongMsg}</div> : null
                                    }
                                    {/* in below line changed class name from md-4 to md-3 -----> 13-10-2022 */}
                                    <div className="mb-3 col-md-3 col-12 p-2" >
                                        <label htmlFor="First Name" className="form-label">
                                            First Name <span style={{ color: '#F68324' }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            maxLength={100}
                                            onFocus={(e) => e.target.select()}
                                            onKeyUp={(e) => { if (e.key === 'Backspace' || e.key === 'Delete') { this.changeDetails(e.target.value, 'fname') } }}
                                            onChange={(e) => this.changeDetails(e.target.value, 'fname')}
                                            defaultValue={this.state.First_Name}
                                            placeholder="First Name"
                                            className="form-control"
                                            // id="firstName"
                                            aria-describedby="emailHelp"
                                            style={{ lineHeight: '2.5' }}
                                        /> <span className="errorsProfle" style={{ color: 'red' }}>{this.state.errFirst_Name}</span> <br></br>



                                        <label htmlFor="Last Name" className="form-label">
                                            Last Name <span style={{ color: '#F68324' }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            maxLength={100}
                                            onFocus={(e) => e.target.select()}
                                            onKeyUp={(e) => { if (e.key === 'Backspace' || e.key === 'Delete') { this.changeDetails(e.target.value, 'lname') } }}
                                            onChange={(e) => this.changeDetails(e.target.value, 'lname')}
                                            defaultValue={this.state.Last_Name}
                                            placeholder="Last Name"
                                            className="form-control"
                                            // id="firstName"
                                            aria-describedby="emailHelp"
                                            style={{ lineHeight: '2.5' }}
                                        /> <span className="errorsProfle" style={{ color: 'red' }}>{this.state.errLast_Name}</span> <br></br>



                                        <label htmlFor="Phone Number" className="form-label">
                                            Phone Number(Optional)
                                        </label>
                                        <input
                                            // type="number"
                                            maxLength={20}
                                            onFocus={(e) => e.target.select()}
                                            onKeyUp={(e) => { if (e.key === 'Backspace' || e.key === 'Delete') { this.changeDetails(e.target.value, 'pNum') } }}
                                            onChange={(e) => this.changeDetails(e.target.value, 'pNum')}
                                            defaultValue={this.state.Phone_Number}
                                            placeholder="Phone Number"
                                            className="form-control"
                                            // id="firstName"
                                            aria-describedby="emailHelp"
                                            style={{ lineHeight: '2.5' }}
                                        /> <span className="errorsProfle" style={{ color: 'red' }}>{this.state.errPhone_Number}</span><br></br>


                                        <label htmlFor="First Name" className="form-label">
                                            New Password <span style={{ color: '#F68324' }}>*</span>
                                        </label>
                                        <input
                                            type="password" onPaste={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }}
                                            minLength={8}
                                            maxLength={20}
                                            onFocus={(e) => e.target.select()}
                                            defaultValue={this.state.New_Pass}
                                            onKeyUp={(e) => { if (e.key === 'Backspace' || e.key === 'Delete') { this.changeDetails(e.target.value, 'nPass') } }}
                                            onChange={(e) => this.changeDetails(e.target.value, 'nPass')}
                                            placeholder="New Password"
                                            className="form-control"
                                            // id="firstName"
                                            aria-describedby="emailHelp"
                                            style={{ lineHeight: '2.5' }}
                                        /><span className="errorsProfle" style={{ color: 'red' }}>{this.state.errNew_Pass}</span> <br></br>


                                        <label htmlFor="password" className="form-label p-2">
                                            Confirm Password <span style={{ color: '#F68324' }}>*</span>
                                        </label>
                                        <input
                                            type="password" onPaste={(e) => {
                                                e.preventDefault()
                                                return false;
                                            }}
                                            minLength={8}
                                            maxLength={20}
                                            onFocus={(e) => e.target.select()}
                                            defaultValue={this.state.Confirm_Pass}
                                            onKeyUp={(e) => { if (e.key === 'Backspace' || e.key === 'Delete') { this.changeDetails(e.target.value, 'cPass') } }}
                                            onChange={(e) => this.changeDetails(e.target.value, 'cPass')}
                                            placeholder="Confirm Password"
                                            className="form-control"
                                            // id="firstName"
                                            aria-describedby="emailHelp"
                                            style={{ lineHeight: '2.5' }}
                                        />  <span className="errorsProfle" style={{ color: 'red' }}>{this.state.errConfirm_Pass}</span> <br></br>
                                    </div>



                                    {/* in below line changed class name from md-4 to md-3 -----> 13-10-2022 */}
                                    <div className="mb-3 col-md-3 col-12 p-2">
                                        <label htmlFor="Email Address" className="form-label">
                                            Email Address <span style={{ color: '#F68324' }}>*</span>
                                        </label>
                                        <input
                                            type="email"
                                            value={this.state.Email_Address}
                                            readOnly placeholder="Email ID"
                                            className="form-control"
                                            // id="firstName"
                                            aria-describedby="emailHelp"
                                            style={{ lineHeight: '2.5' }}
                                        /> <br></br>


                                        <label htmlFor="Company Name" className="form-label">
                                            Company Name <span style={{ color: '#F68324' }}>*</span>
                                        </label>
                                        <input type="text"
                                            maxLength={150}
                                            onFocus={(e) => e.target.select()}
                                            onKeyUp={(e) => { if (e.key === 'Backspace' || e.key === 'Delete') { this.changeDetails(e.target.value, 'cname') } }}
                                            onChange={(e) => this.changeDetails(e.target.value, 'cname')}
                                            defaultValue={this.state.Company_Name}
                                            placeholder="company Name"
                                            className="form-control"
                                            // id="firstName"
                                            aria-describedby="emailHelp"
                                            style={{ lineHeight: '2.5' }}
                                        />  <span className="errorsProfle" style={{ color: 'red' }}>{this.state.errCompany_Name}</span> <br></br>



                                        <label htmlFor="Role" className="form-label">
                                            Role <span style={{ color: '#F68324' }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            maxLength={100} onFocus={(e) => e.target.select()}
                                            onKeyUp={(e) => { if (e.key === 'Backspace' || e.key === 'Delete') { this.changeDetails(e.target.value, 'role') } }}
                                            onChange={(e) => this.changeDetails(e.target.value, 'role')}
                                            defaultValue={this.state.Role}
                                            placeholder="Role"
                                            className="form-control"
                                            // id="firstName"
                                            aria-describedby="emailHelp"
                                            style={{ lineHeight: '2.5' }}
                                        /> <span className="errorsProfle" style={{ color: 'red' }}>{this.state.errRole}</span><br></br>



                                        <label htmlFor="Address" className="form-label">Address <span style={{ color: '#F68324' }}>*</span></label>
                                        <textarea className="form-control"
                                            placeholder="Address"
                                            style={{ lineHeight: '2.0' }}
                                            maxLength={150}
                                            onFocus={(e) => e.target.select()}
                                            onKeyUp={(e) => { if (e.key === 'Backspace' || e.key === 'Delete') { this.changeDetails(e.target.value, 'address') } }}
                                            onChange={(e) => this.changeDetails(e.target.value, 'address')}
                                            defaultValue={this.state.Address}
                                            rows="5">
                                        </textarea>   <span className="errorsProfle" style={{ color: 'red' }}>{this.state.errAddress}</span> <br></br>
                                    </div>




                                    {/* in below line changed class name from md-4 to md-3 -----> 13-10-2022 */}
                                    <div className="mb-3 col-md-3 col-12 p-2">
                                        <label htmlFor="Industry" className="form-label">
                                            Industry(Optional)
                                        </label>
                                        <input
                                            type="text"
                                            maxLength={100}
                                            onFocus={(e) => e.target.select()}
                                            onKeyUp={(e) => { if (e.key === 'Backspace' || e.key === 'Delete') { this.changeDetails(e.target.value, 'industry') } }}
                                            onChange={(e) => this.changeDetails(e.target.value, 'industry')}
                                            defaultValue={this.state.Industry}
                                            placeholder="Industry"
                                            className="form-control"
                                            // id="firstName"
                                            aria-describedby="emailHelp"
                                            style={{ lineHeight: '2.5' }}
                                        /><span className="errorsProfle" style={{ color: 'red' }}>{this.state.errIndustry}</span> <br></br>



                                        <label htmlFor="City" className="form-label">
                                            City <span style={{ color: '#F68324' }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            maxLength={100}
                                            onFocus={(e) => e.target.select()} onKeyUp={(e) => { if (e.key === 'Backspace' || e.key === 'Delete') { this.changeDetails(e.target.value, 'city') } }}
                                            onChange={(e) => this.changeDetails(e.target.value, 'city')}
                                            defaultValue={this.state.City}
                                            placeholder="City"
                                            className="form-control"
                                            // id="firstName"
                                            aria-describedby="emailHelp"
                                            style={{ lineHeight: '2.5' }}
                                        /> <span className="errorsProfle" style={{ color: 'red' }}>{this.state.errCity}</span> <br></br>


                                        <label htmlFor="State" className="form-label">
                                            State <span style={{ color: '#F68324' }}>*</span>
                                        </label>
                                        <div >
                                            <select className="w-100  rounded form-control" style={{ lineHeight: '2.5' }} value={this.state.selectedState} onChange={(e) => this.setState({ selectedState: e.target.value, changesDone: true })}>
                                                {this.displayStates(states)}
                                            </select>
                                        </div>
                                        <br></br>


                                        <label htmlFor="Zip Code" className="form-label">
                                            Zip Code <span style={{ color: '#F68324' }}>*</span>
                                        </label>
                                        <input
                                            type="text"
                                            maxLength={20}
                                            onFocus={(e) => e.target.select()}
                                            onKeyUp={(e) => { if (e.key === 'Backspace' || e.key === 'Delete') { this.changeDetails(e.target.value, 'zip') } }}
                                            onChange={(e) => this.changeDetails(e.target.value, 'zip')}
                                            defaultValue={this.state.Zip}
                                            placeholder="Zip Code"
                                            className="form-control"
                                            // id="firstName"
                                            aria-describedby="emailHelp"
                                            style={{ lineHeight: '2.5' }}
                                        /> <span className="errorsProfle" style={{ color: 'red' }}>{this.state.errZip}</span><br></br>

                                        <button type="submit" id="login-submit" className="text-light fw-bold btn  btn-warning px-5 py-1.2"> Save</button> <p></p>
                                        {
                                            this.state.changesDone ?
                                                <div className="col col2">
                                                    <Popconfirm
                                                        id='userpop'
                                                        title="Are you sure, you want to modify the details?"
                                                        onConfirm={(e) => this.goBackToProjectScreen(e)}
                                                        onCancel={this.cancelBack}
                                                        okText="Yes"
                                                        cancelText="Cancel"
                                                    >
                                                        <button id="Resetbutt" type="button" className="btn btn-dark fw-bold form-control">Back</button>
                                                    </Popconfirm>
                                                </div> :
                                                <div className="col col2">
                                                    <button id="Resetbutt" type="button" className="btn btn-dark fw-bold form-control " onClick={() => this.clickedBackButton()}>Back</button>
                                                </div>
                                        }
                                    </div>
                                </div>
                            </form>
                        </div>
                        <hr style={{ color: '#ccc' }} />
                    </div>
                </div>
            </>
        )
    }
}
function UpdateProfile(props) {
    let navigate = useNavigate();
    var CryptoJS = require("crypto-js");
    return <UpdateProfile1 {...props} navigate={navigate} />
}
export default UpdateProfile;