export const loginCheck = "/api2/dblayer/user/loginldap/"

export const createUser = "/api2/dblayer/user/";
export const getUserDetails = "/api2/dblayer/user/"
export const updateUserDetails = "/api2/dblayer/user/"
export const newUserRegisterInLdap = "/api2/dblayer/userldap/"
export const updateNewPasswordInLdap = "/api2/dblayer/userLDAPcp/"

export const getProjects = "/api2/dblayer/project/"
export const createProjectUrl = "/api2/dblayer/project/"
export const updateProject = "/api2/dblayer/project/"

export const createDocument = "/api2/dblayer/document/"
export const getDocumentDetails = "/api2/dblayer/document/"
export const updateDocumentDetails = "/api2/dblayer/document/"
 
export const specialCharsError = "Special characters * = ; not allowed"
export const NumbersError = "Numbers not allowed"
export const specialCharsNoError = "Numbers and special characters * = ; not allowed"
export const AlphaSpecialCharsError = "Alphabets and special characters * = ; not allowed"

export const GetTaxDetails = "/api2/dblayer/schedule/"
export const GetTaxContentDetails = "/api2/dblayer/scheduletax/"
export const UpdateTaxDetails = "api2/dblayer/tax/"

export const uploadUrl = "/api2/dblayer/upload/"
export const configData = "/api2/dblayer/config/"
export const DownloadPdf = "/api2/dblayer/file/"
export const getPdfUrl = "/api2/dblayer/file/"
export const sendEmail = "/api2/dblayer/email/"

export const retrievepass = "/api2/dblayer/retrievepass/"

export const enqueueUrl = "/api1/jms/enqueue?payload=/opt/data/"
export const createAuditDetails = "/api2/dblayer/audit/"

export const DeletepdfFile = "/api2/dblayer/deletesdocument/"
// export const DeletepdfFile = "/deletesdocument/{userId}/{proId}/{docId}/{filename}"
// export const DeleteProject ="/deleteproject/{userId}/{proId}"
export const DeleteProject = "/api2/dblayer/deleteproject/"

export const EnqueUserName = "lattice"
export const EnqueUserPassword = "science"

export const ReCalDescripency = '/api3/etl/validate?guid='


export const externalLink = process.env.REACT_APP_HOST_UI

export const AdminApprovelURL = "https://taxxl.nambri.com"

var BasicAuth = new Headers();
BasicAuth.set('Authorization', `Basic ${window.btoa(`${process.env.REACT_APP_AuthenticationName}:${process.env.REACT_APP_AuthenticationPassword}`)}`);

export var BasicAuth;

var BasicAuth2 = new Headers();
BasicAuth2.set('Authorization', `Basic ${window.btoa(`${process.env.REACT_APP_AuthenticationName2}:${process.env.REACT_APP_AuthenticationPassword2}`)}`);

export var BasicAuth2;

export function ReadyAPIBody(str) {

    var body = JSON.parse(str);
    body.Authusername = process.env.REACT_APP_AUTH2NAME;
    body.Authpassword = process.env.REACT_APP_AUTH2PASS;
    // console.log("prepared body from JavaAPI=", body);

    return JSON.stringify(body);
}




